import React, {ReactText} from 'react';
import {Box, BoxProps, Flex, FlexProps, Icon, Link, useColorModeValue, useDisclosure,} from '@chakra-ui/react';
import {FiCompass, FiHome, FiSettings, FiStar, FiTrendingUp,} from 'react-icons/fi';
import {IconType} from 'react-icons';
import Header from "../../components/header/header.component";
import {Link as ReachLink, Outlet} from 'react-router-dom';
import {COLORS, SIZES} from "../../assets/theme/theme";

//import { Link as ReachLink } from "@reach/router"


interface LinkItemProps {
    name: string;
    icon: IconType;
    path: string;
}

const LinkItems: Array<LinkItemProps> = [
    {name: 'Task Roadmap', icon: FiHome, path: '/app/task-roadmap'},
    {name: 'Workflow', icon: FiTrendingUp, path: '/app/workflow'},
    {name: 'Project Summary', icon: FiCompass, path: '/app/project-summery'},
    {name: 'All Application', icon: FiStar, path: '/all-application'},
    {name: 'Received-Application', icon: FiSettings, path: '/received-application'},
    {name: 'Profile', icon: FiSettings, path: '/profile'},
    {name: 'Todo Task', icon: FiSettings, path: '/task'},
    {name: 'Task History', icon: FiSettings, path: '/task/task-history'},
    {name: 'Document', icon: FiSettings, path: '/app/document-view'},
    {name: 'Organization Profile', icon: FiSettings, path: '/organization'},
    {name: 'Department', icon: FiSettings, path: '/department'},
    {name: 'Model Page', icon: FiSettings, path: '/model'},
    {name: 'User', icon: FiSettings, path: '/user'},
    {name: 'Application View', icon: FiSettings, path: '/application-view'}];

const MainLayout = () => {
    const {isOpen, onOpen, onClose} = useDisclosure();

    return (
        <>
            <Header/>
            <Box minH={SIZES.mainLayoutHeight} bg={useColorModeValue('gray.100', 'gray.900')}>
                <SidebarContent
                    onClose={() => onClose}
                    display={{base: 'none', md: 'block'}}
                />
                <Box minH={SIZES.mainLayoutHeight} bg={useColorModeValue('white', 'gray.900')} ml={{base: 0, md: 60}}
                     p="4">
                    <Outlet/>
                </Box>
            </Box>
        </>
    );
}


interface SidebarProps extends BoxProps {
    onClose: () => void;
}

const SidebarContent = ({onClose, ...rest}: SidebarProps) => {
    return (
        <Box
            borderRight="1px"
            borderRightColor={useColorModeValue("gray.200", 'gray.700')}
            bg={useColorModeValue(COLORS.gray10, 'grey.700')}
            w={{base: 'full', md: 60}}
            py={'2'}
            pos="fixed"
            h="full"
            {...rest}>
            {LinkItems.map((link) => (
                <NavItem p={'2'} key={link.name} icon={link.icon} path={link.path}>
                    {link.name}
                </NavItem>
            ))}
        </Box>
    );
};

interface NavItemProps extends FlexProps {
    icon: IconType;
    children: ReactText;
    path: string
}

const NavItem = ({icon, children, path, ...rest}: NavItemProps) => {

    return (
        <Link as={ReachLink} to={path} style={{textDecoration: 'none'}} _focus={{boxShadow: 'none'}}>
            <Flex
                align="center"
                p="4"
                mx="2"
                bg={COLORS.gray10}
                borderRadius="lg"
                role="group"
                cursor="pointer"
                _hover={{
                    bg: COLORS.gray30,
                    color: COLORS.primaryBlue,
                }}
                {...rest}>
                {icon && (
                    <Icon
                        mr="4"
                        fontSize="16"
                        _groupHover={{
                            color: COLORS.primaryBlue,
                        }}
                        as={icon}
                    />
                )}
                {children}
            </Flex>
        </Link>
    );
};

export default MainLayout