import PageHeader from "../../components/page-header/page-header.component";
import {
    Avatar,
    AvatarGroup,
    Box,
    Button,
    Input,
    InputGroup,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    SimpleGrid,
    Stack
} from "@chakra-ui/react";
import {ChevronDownIcon} from "@chakra-ui/icons";
import RoadMapItem from "./component/RoadMapItem";
import RoadMapItemContainer from "./component/RoadMapItemContainer";

const header = (
    <>
        <PageHeader title={"Doc Id/ Project name"}/>
        <Box py={4}>
            <Stack
                direction={'row'}
                spacing={4}>

                {/*SEARCH*/}
                <InputGroup width={200} size={'sm'}>
                    <Input placeholder='Search'/>
                </InputGroup>

                {/*AVATAR GROUP*/}
                <AvatarGroup size='sm' max={5}>
                    <Avatar name='Ryan Florence' src='https://bit.ly/ryan-florence'/>
                    <Avatar name='Segun Adebayo' src='https://bit.ly/sage-adebayo'/>
                    <Avatar name='Kent Dodds' src='https://bit.ly/kent-c-dodds'/>
                    <Avatar name='Prosper Otemuyiwa' src='https://bit.ly/prosper-baba'/>
                    <Avatar name='Christian Nwamba' src='https://bit.ly/code-beast'/>
                    <Avatar name='Christian Nwamba' src='https://bit.ly/code-beast'/>
                </AvatarGroup>

                {/*CHOICES DROPDOWN*/}
                <Menu size={'sm'}>
                    <MenuButton size={'sm'} as={Button} rightIcon={<ChevronDownIcon/>}>
                        Choices
                    </MenuButton>
                    <MenuList>
                        <MenuItem>Download</MenuItem>
                        <MenuItem>Create a Copy</MenuItem>
                        <MenuItem>Mark as Draft</MenuItem>
                        <MenuItem>Delete</MenuItem>
                        <MenuItem>Attend a Workshop</MenuItem>
                    </MenuList>
                </Menu>
            </Stack>
        </Box>
    </>
)

const TaskRoadMap = () => {
    return (
        <>
            {header}
            <SimpleGrid columns={4} gap={4} spacing={10}>
                <RoadMapItemContainer title={"TODO"}>
                    <RoadMapItem/>
                    <RoadMapItem/>
                    <RoadMapItem/>
                    <RoadMapItem/>
                    <RoadMapItem/>
                    <RoadMapItem/>
                    <RoadMapItem/>
                    <RoadMapItem/>
                    <RoadMapItem/>
                    <RoadMapItem/>
                </RoadMapItemContainer>

                <RoadMapItemContainer title={"IN PROGRESS"}>
                    <RoadMapItem/>
                </RoadMapItemContainer>

                <RoadMapItemContainer title={"PENDING"}>
                    <RoadMapItem/>
                    <RoadMapItem/>
                    <RoadMapItem/>
                </RoadMapItemContainer>

                <RoadMapItemContainer title={"DONE"}>
                    <RoadMapItem/>
                    <RoadMapItem/>
                </RoadMapItemContainer>
            </SimpleGrid>
        </>
    );
}

export default TaskRoadMap