import {
    Center,
    HStack,
    Menu,
    MenuButton,
    MenuGroup,
    MenuItem,
    MenuList,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr
} from "@chakra-ui/react";
import {FaEllipsisH} from "react-icons/fa";

interface table {
    actions: Array<string | {}>,
    columnHeaders: Array<string>,
    rows: Array<any>
}

const MainTable = (props: table) => {

    let columnHeaders = props.columnHeaders
    let actions = props.actions
    let rows = props.rows

    const ActionsMenu = (
        <Center>
            <HStack spacing={5}>
                <Menu>
                    <MenuButton>
                        <FaEllipsisH/>
                    </MenuButton>
                    <MenuList>
                        <MenuGroup title='Action'>
                            {actions.map((items: any) => {
                                return (
                                    <MenuItem onClick={() => {
                                        items.onPress()
                                    }}>{items.label}</MenuItem>
                                )
                            })}
                        </MenuGroup>
                    </MenuList>
                </Menu>
            </HStack>
        </Center>
    );

    return (
        <Table size='sm'>
            <Thead>
                <Tr>{
                    columnHeaders.map((item: any) => {
                        return <Th>{item}</Th>
                    })
                }
                </Tr>
            </Thead>
            <Tbody>
                {rows.map((row: any, index) => {
                    return (
                        <Tr>{row.map((item: any) => {
                            return <Td>{item}</Td>
                        })}
                            <td >
                                {ActionsMenu}
                            </td>
                        </Tr>
                    )
                })}
            </Tbody>
        </Table>
    )
}

export default MainTable