import PageHeader from "../../components/page-header/page-header.component";
import {
    Box,
    FormControl,
    FormLabel,
    HStack,
    Input,
    InputGroup,
    InputRightAddon,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    Wrap,
    WrapItem
} from "@chakra-ui/react";
import SearchableSelect from "../../components/searchable-select/SearchableSelect";
import WorkFlowItem from "../../components/workflow-item/WorkFlowItem";
import {SearchIcon} from "@chakra-ui/icons";
import ProjectSummeryItem from "./component/ProjectSummeryItem";
import OrgSummeryCard from "./component/OrgSummeryCard";

const header = (
    <>
        <PageHeader title={"Projects Summery"}/>
        <Box py={2}>
            <Wrap spacing={"16px"}>
                <WrapItem>
                    <FormControl w={254}>
                        <FormLabel htmlFor='search'>Search</FormLabel>
                        <InputGroup size={"sm"}>
                            <Input size={"sm"} id='search' type='text'/>
                            <InputRightAddon size={"sm"} children={<SearchIcon/>}/>
                        </InputGroup>
                    </FormControl>
                </WrapItem>
                <WrapItem>
                    <SearchableSelect label={"Organization"} placeholder={"Select organization"}/>
                </WrapItem>
                <WrapItem>
                    <SearchableSelect label={"Department"} placeholder={"Select Department"}/>
                </WrapItem>
            </Wrap>
        </Box>
    </>
)

const projectListMarkUp = (
    <Wrap spacing={6} className={"project-summery-container"} w={"100%"} maxH={430} overflowY={"auto"}
          overflowX={"hidden"}>
        <WrapItem>
            <ProjectSummeryItem/>
        </WrapItem>
        <WrapItem>
            <ProjectSummeryItem/>
        </WrapItem>
        <WrapItem>
            <ProjectSummeryItem/>
        </WrapItem>
        <WrapItem>
            <ProjectSummeryItem/>
        </WrapItem>
        <WrapItem>
            <ProjectSummeryItem/>
        </WrapItem>
        <WrapItem>
            <ProjectSummeryItem/>
        </WrapItem>
        <WrapItem>
            <ProjectSummeryItem/>
        </WrapItem>
        <WrapItem>
            <ProjectSummeryItem/>
        </WrapItem>
        <WrapItem>
            <ProjectSummeryItem/>
        </WrapItem>
        <WrapItem>
            <ProjectSummeryItem/>
        </WrapItem>
        <WrapItem>
            <ProjectSummeryItem/>
        </WrapItem>
        <WrapItem>
            <ProjectSummeryItem/>
        </WrapItem>
    </Wrap>
)

const comments = (
    <>
        <WorkFlowItem title={"Send to ABC department"}/>
        <WorkFlowItem title={"Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus"}/>
        <WorkFlowItem title={"Lorem ipsum dolor sit amet, consectetur adipiscing elit"}/>
        {/*<WorkFlowItem title={"Lorem ipsum dolor sit amet"}/>*/}
    </>
)

const ProjectSummeryPage = () => {
    return (
        <>
            {header}

            <HStack>
                <OrgSummeryCard/>
                {projectListMarkUp}
            </HStack>

            <Tabs>
                <TabList>
                    <Tab _focus={{
                        outline: "none !important",
                        boxShadow: "none !important",
                    }}>
                        <Text fontWeight={"semibold"} fontSize='md'>Activity</Text>
                    </Tab>
                </TabList>

                <TabPanels>
                    <TabPanel>
                        {comments}
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    );
}

export default ProjectSummeryPage;