import {FormControl, FormLabel} from "@chakra-ui/react";

import DATA from "./data";
import {Select} from "chakra-react-select";

const SearchableSelect = (props: any) => {
    return (
        <FormControl w={254}>
            <FormLabel>
                {props.label}
            </FormLabel>
            <Select
                name={props.label}
                options={DATA}
                placeholder={props.placeholder}
                closeMenuOnSelect={true}
                size="sm"
            />
        </FormControl>
    )
}
export default SearchableSelect;