import {
    Flex,
    Box,
    FormControl,
    Input,
    Stack,
    Link,
    Button,
    Heading,
    Text,
    useColorModeValue, Img, Image, Icon,
} from '@chakra-ui/react'
import {useNavigate} from "react-router-dom";
import logo from '../../assets/images/nia_logo.png'
import deliganLogo from '../../assets/images/deligan_logo.png'

const LoginPage = () => {

    const navigate = useNavigate()

    return (
        <Flex
            minH={'100vh'}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('gray.50', 'gray.800')}>
                <Stack spacing={5}>
                    <Stack align={'center'}>
                        <Stack direction={'row'} align={'center'}>
                            <Stack w="30%" mt="5%">
                                <Image src={logo}/>
                            </Stack>
                            <Heading fontSize={'4xl'} color={"midnightblue"}>NIA</Heading>
                        </Stack>
                    </Stack>
                    <Box
                        bg={useColorModeValue('white', 'gray.700')}
                        boxShadow={'lg'}
                        py={5} px={5} w="350px">
                        <Stack align={'center'} mb={5}>
                            <Text fontSize={'lg'} color={'gray.600'}>
                                Sign in to your account️
                            </Text>
                        </Stack>
                        <Stack spacing={4}>
                            <FormControl id="email">
                                <Input type="email" placeholder="Username"/>
                            </FormControl>
                            <FormControl id="password">
                                <Input type="password" placeholder="Password"/>
                            </FormControl>
                            <Stack>
                                <Button
                                    bg={'blue.400'}
                                    color={'white'}
                                    _hover={{
                                        bg: 'blue.500',
                                    }}
                                    onClick={() => navigate('/app')}
                                >
                                    Sign in
                                </Button>
                            </Stack>
                        </Stack>
                        <Stack spacing={5} mt={5}>
                            <hr/>
                            <Stack align={'center'} textDecoration={'none'}>
                                <Link color='teal.500' href='#'>Can't sign in?</Link>
                            </Stack>
                        </Stack>
                    </Box>
                </Stack>
                <Stack align={'center'} position={'absolute'} bottom={'5'}>
                    <Stack w="350px">
                        <hr/>
                    </Stack>
                    <Stack >
                        <Image src={deliganLogo}/>
                    </Stack>
                </Stack>
        </Flex>
    )
}

export default LoginPage