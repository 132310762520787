import {Avatar, Box, Flex, Grid, GridItem, Heading, HStack, Image, Link, Spacer, Stack, Text} from "@chakra-ui/react";
import Card from "../../components/card/Card";
import editIcon from "../../assets/images/shape.png";
import ViewItem from "../../components/view-item/ViewItem";
import ItemLine from "../../components/ItemLine/ItemLine";
import WorkFlowItem from "../../components/workflow-item/WorkFlowItem";

const OrganizationProfilePage = () => {
    return (
        <>
            <Image objectFit='cover' width='100%' height='60' src='https://bit.ly/2Z4KKcF' alt='Dan Abramov'/>
            <Box>
                <Grid
                    h='200px'
                    templateRows='repeat(2, 1fr)'
                    templateColumns='repeat(11, 1fr)'
                    gap={4}>
                    <GridItem colSpan={2} rowSpan={1}></GridItem>
                    <GridItem colSpan={7} rowSpan={1} mt={"-90"}>
                        < HStack alignItems={"start"} width={"100%"}>
                            <Box>
                                <Avatar h={150} w={150} mt={4} name='Segun Adebayo'
                                        src='https://bit.ly/sage-adebayo'/>
                            </Box>
                            <Box ps={2} pt={5} width={'100%'}>
                                <Card border padding>
                                    <Flex>
                                        <Box>
                                            <Heading size={"md"}>Organization Name</Heading>
                                        </Box>
                                        <Spacer/>
                                        <Box mt={0.5} mr={1}>
                                            <Link><Image src={editIcon}/></Link>
                                        </Box>
                                    </Flex>

                                    <text>Organization Code</text>

                                    <HStack mb={0} mt={7}>
                                        <Grid templateColumns='repeat(3, 1fr)' gap={3}>
                                            <GridItem>
                                                <Card border padding>
                                                    <Flex>
                                                        <Box>
                                                            <Text fontSize={"sm"} mt={'2px'}>Departments:</Text>
                                                        </Box>
                                                        <Spacer/>
                                                        <Box>
                                                            <Text fontWeight={"bold"} fontSize={"md"} ps={3}>12</Text>
                                                        </Box>
                                                    </Flex>
                                                </Card>
                                            </GridItem>

                                            <GridItem>
                                                <Card border padding>
                                                    <Flex>
                                                        <Box>
                                                            <Text fontSize={"sm"} mt={'2px'}>Users:</Text>
                                                        </Box>
                                                        <Spacer/>
                                                        <Box>
                                                            <Text fontWeight={"bold"} fontSize={"md"} ps={3}>180</Text>
                                                        </Box>
                                                    </Flex>
                                                </Card>
                                            </GridItem>

                                            <GridItem>
                                                <Card border padding>
                                                    <Flex>
                                                        <Box>
                                                            <Text fontSize={"sm"} mt={'2px'}>Projects:</Text>
                                                        </Box>
                                                        <Spacer/>
                                                        <Box>
                                                            <Text fontWeight={"bold"} fontSize={"md"} ps={3}>856</Text>
                                                        </Box>
                                                    </Flex>
                                                </Card>
                                            </GridItem>
                                        </Grid>
                                    </HStack>
                                </Card>

                            </Box>

                        </ HStack>
                    </GridItem>
                    <GridItem colSpan={2} rowSpan={1}></GridItem>
                    {/*<----------------------------------------------------------------------------------------------------------->*/}
                    <GridItem colSpan={2} rowSpan={1}></GridItem>
                    <GridItem colSpan={3}>

                        <Card border padding>
                            <Card bottomSpace title={"about"}>

                                <ViewItem label="Registration no" value="123568" onHalf></ViewItem>
                                <ViewItem label="Name" value="123568" onHalf></ViewItem>
                                <ViewItem label="Full name" value="Dasun Edirisinghe" onHalf></ViewItem>
                                <ViewItem label="NIC" value="2568569552" onHalf></ViewItem>

                            </Card>
                            <Card bottomSpace title={"residence"}>

                                <ViewItem label="Address" value="Lorem ipsum dolor sit amet, consectetur adipiscing elit ut*
                                          aliquam, purus sit amet luctus" onHalf></ViewItem>
                                <ViewItem label="Address line1" value="Lorem Ipsum" onHalf></ViewItem>
                                <ViewItem label="Address line2" value="Lorem Ipsum" onHalf></ViewItem>
                                <ViewItem label="City" value=" Lorem Ipsum"></ViewItem>
                                <ViewItem label="Postal code" value="90542"></ViewItem>
                            </Card>
                            <Card bottomSpace title={"Contact"}>

                                <ViewItem label="Phone Number" value="0778899556" onHalf></ViewItem>
                                <ViewItem label="Home" value="0348515121" onHalf></ViewItem>
                                <ViewItem label=" Email" value="example@gmail.com" onHalf></ViewItem>

                            </Card>
                        </Card>
                    </GridItem>
                    <GridItem colSpan={4} mt={0}>
                        <Card>
                            <Card heading={"Applications"}>
                                <Box paddingLeft="20px" border="1px" borderColor="#E8E8E8" paddingBottom={"10px"}
                                     paddingTop="10px"

                                     overflowX="hidden">
                                    <ItemLine title={"DOC2021/04/30/DOCAB"}
                                              subTitle={"lorim ipsum doter sit"}
                                    />
                                    <ItemLine title={"DOC2021/04/30/DOCAB"}
                                              subTitle={"lorim ipsum doter sit"}
                                    />
                                    <ItemLine title={"DOC2021/04/30/DOCAB"}
                                              subTitle={"lorim ipsum doter sit"}
                                    />

                                </Box>
                            </Card>

                            <Card heading={"Activity"} topSpace bottomSpace>
                                <Box padding="20px" border="1px" borderColor="#E8E8E8" paddingTop="10px" h="384px"
                                     maxH="384px"
                                     overflow="scroll"
                                     overflowX="hidden">
                                    <Text fontSize='md'>TODAY</Text>
                                    <Stack alignItems="left" justifyItems="center"
                                           direction={['column', 'row']} spacing='30px'>
                                        <WorkFlowItem/>
                                    </Stack>
                                    <Stack alignItems="left" justifyItems="center"
                                           direction={['column', 'row']} spacing='30px'>
                                        <WorkFlowItem/>
                                    </Stack>
                                    <Stack alignItems="left" justifyItems="center"
                                           direction={['column', 'row']} spacing='30px'>
                                        <WorkFlowItem/>
                                    </Stack>
                                    <Stack alignItems="left" justifyItems="center"
                                           direction={['column', 'row']} spacing='30px'>
                                        <WorkFlowItem/>
                                    </Stack>
                                    <Text fontSize='md'>YESTERDAY</Text>
                                    <Stack alignItems="left" justifyItems="center"
                                           direction={['column', 'row']} spacing='30px'>
                                        <WorkFlowItem/>
                                    </Stack>
                                    <Stack alignItems="left" justifyItems="center"
                                           direction={['column', 'row']} spacing='30px'>
                                        <WorkFlowItem/>
                                    </Stack>
                                    <Stack alignItems="left" justifyItems="center"
                                           direction={['column', 'row']} spacing='30px'>
                                        <WorkFlowItem/>
                                    </Stack>
                                </Box>
                            </Card>
                        </Card>
                    </GridItem>
                    <GridItem colSpan={2}></GridItem>
                </Grid>
            </Box>
        </>
    )
}

export default OrganizationProfilePage