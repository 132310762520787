import {Badge, Box, Checkbox, HStack, Text, VStack} from "@chakra-ui/react";

const RoadMapItem = () => {
    return (
        <Box
            bg={"white"}
            m={2}
            rounded={"sm"}
            boxShadow={"md"}
        >
            <VStack alignItems={"start"}>
                <Box m={3}>
                    <Text color={"blackAlpha.800"} fontSize={"sm"}> Tincidunt proin ultricies eu, bibendum lectus
                        ipsum consequat lobortis amet... </Text>
                    <HStack my={2}>
                        <Badge colorScheme='yellow'>Department</Badge>
                        <Badge colorScheme='cyan'>Organization</Badge>
                    </HStack>
                    <HStack mt={2}>
                        <Checkbox fontWeight={"medium"} color={"blackAlpha.600"} defaultIsChecked>FMCE-34</Checkbox>
                        <Badge colorScheme='red'>FEB-24</Badge>
                    </HStack>
                </Box>
            </VStack>

        </Box>
    )
}

export default RoadMapItem;