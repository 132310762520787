import PageHeader from "../../components/page-header/page-header.component";
import {
    Box,
    Button, Checkbox, Divider, Flex, FormControl, FormLabel, Grid, GridItem, HStack, Image,
    Input,
    InputGroup, Link,
    Menu,
    MenuButton,
    MenuItem,
    MenuList, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid,
    Stack, Switch, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Textarea,
    useDisclosure, VStack, Wrap, WrapItem
} from "@chakra-ui/react";
import {ChevronDownIcon, CloseIcon} from "@chakra-ui/icons";
import MainTable from "../../components/main-table/main-table.component";
import React from "react";
import {useNavigate} from "react-router-dom";
import {
    SelectBox,
    SelectBoxForAssignee,
    SelectBoxForDepartment,
    SelectBoxForOrganizations
} from "../model/components/searchable-select-for-model/select-box";
import priorityIcon from "../../assets/images/priority_icon.png";
import {BiDotsHorizontalRounded, BiLike, BiLockOpen, BiShareAlt} from "react-icons/bi";
import {BsEyeFill} from "react-icons/bs";
import WorkFlowItem from "../../components/workflow-item/WorkFlowItem";
import Card from "../../components/card/Card";
import RichViewItem from "../../components/rich-view-item/rich-view-item";

const UsersPage = () => {

    const initialRef = React.useRef<any>(null);
    const {isOpen, onOpen, onClose} = useDisclosure();
    const {
        isOpen: isAddUserPrivilegesModel,
        onOpen: onAddUserPrivilegesModel,
        onClose: onCloseAddUserPrivilegesModel
    } = useDisclosure()

    const {
        isOpen: isOpenAssigneesModel,
        onOpen: onOpenAssigneesModel,
        onClose: onCloseAssigneesModel
    } = useDisclosure()

    const {
        isOpen: isOpenAssingJobModel,
        onOpen: onOpenAssingJobModel,
        onClose: onCloseAssingJobModel
    } = useDisclosure()
    const finalRef = React.useRef<any>(null);

    const navigate = useNavigate()

    const column = ['Username', 'User Id', 'Department', 'Email Address', 'Action']
    const rows = [['Lorem Ipsum', 'E20220101', 'Department 1', 'loremipsum@gmail.com'],
        ['Lorem Ipsum', 'E20220101', 'Department 2', 'loremipsum@gmail.com'],
        ['Lorem Ipsum', 'E20220101', 'Department 3', 'loremipsum@gmail.com'],
        ['Lorem Ipsum', 'E20220101', 'Department 4', 'loremipsum@gmail.com'],
        ['Lorem Ipsum', 'E20220101', 'Department 5', 'loremipsum@gmail.com'],
        ['Lorem Ipsum', 'E20220101', 'Department 6', 'loremipsum@gmail.com'],
        ['Lorem Ipsum', 'E20220101', 'Department 7', 'loremipsum@gmail.com']]

    const actions = [
        {
            value: 'a',
            label: 'View Profile',
            onPress: () => {
                navigate("/profile")


            }
        },
        {
            value: 'b',
            label: 'Add User Privilege',
            onPress: () => {
                onAddUserPrivilegesModel()
            }
        },
        {
            value: 'c',
            label: 'Assign',
            onPress: () => {
                onOpenAssingJobModel()
            }
        },
    ]

    const AssigneesModel = () => {

        return (
            <>
                <Modal size="3xl" isOpen={isOpenAssigneesModel} onClose={onCloseAssigneesModel} isCentered>
                    <ModalOverlay/>
                    <ModalContent>
                        <ModalHeader fontSize={"20px"}>External assignees</ModalHeader>
                        <ModalBody>
                            <SimpleGrid
                                columns={3}
                                spacing='40px'
                            >
                                <Box>
                                    <FormControl>
                                        <FormLabel color={"gray.500"} fontSize={'sm'}>Organizations</FormLabel>
                                        <SelectBoxForOrganizations placeholder={"Select Organizations "}/>
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl>
                                        <FormLabel color={"gray.500"} fontSize={'sm'}>Departments</FormLabel>
                                        <SelectBoxForDepartment placeholder={"Select Departments "}/>
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl>
                                        <FormLabel color={"gray.500"} fontSize={'sm'}>Assignee</FormLabel>
                                        <SelectBoxForAssignee placeholder={"Select Assignee "}/>
                                    </FormControl>
                                </Box>
                            </SimpleGrid>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="solid" mr={3} onClick={onCloseAssigneesModel} size="sm">
                                Cancel
                            </Button>
                            <Button colorScheme="blue" size="sm">
                                Confirm
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </>
        );
    };

    const AssignJobModel = () => {

        const ModelHeader = () => {
            return (<VStack>
                    <Box mt={3} width={"100%"}>
                        <HStack display={"flex"} justifyContent={"space-between"}>
                            <Box>
                                <Wrap spacing={2}>
                                    <WrapItem>
                                        <Image w={4} pt={0.5} mt={0.2} src={priorityIcon}/>
                                        {/*<BsFillBrightnessHighFill size={40}/>*/}
                                    </WrapItem>
                                    <WrapItem fontWeight={700} alignItems={"center"}><Text color={"#6B778C"}
                                                                                           fontSize={"12px"}> ATL-001</Text></WrapItem>
                                    <WrapItem><Text fontSize={"14px"}>Issue summary</Text></WrapItem>
                                </Wrap>
                            </Box>
                            <Box>
                                <Wrap spacing={6}>
                                    <WrapItem>
                                        <Link><BiLockOpen size={20} color={"#4b5669"}/></Link>
                                    </WrapItem>
                                    <WrapItem>
                                        <Link><BsEyeFill size={20} color={"#4b5669"}/></Link>
                                    </WrapItem>
                                    <WrapItem>
                                        <Link><BiLike size={20} color={"#4b5669"}/></Link>
                                    </WrapItem>
                                    <WrapItem>
                                        <Link><BiShareAlt size={20} color={"#4b5669"}/></Link>
                                    </WrapItem>
                                    <WrapItem>
                                        <Link><BiDotsHorizontalRounded size={20} color={"#4b5669"}/></Link>
                                    </WrapItem>
                                    <WrapItem>
                                        <Link> <CloseIcon mt={"-10px"} onClick={onCloseAssingJobModel}
                                                          color={"#4b5669"}/></Link>
                                    </WrapItem>

                                </Wrap>
                            </Box>
                        </HStack>
                    </Box>
                    <Box alignSelf={"flex-start"}>
                        <Text fontWeight={500} fontSize={"24px"}> DOC2021-12-01DEC</Text>
                    </Box>
                </VStack>
            )
        }

        const comments = (
            <>
                <WorkFlowItem title={"Send to ABC department"}/>
                <WorkFlowItem
                    title={
                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus"
                    }/>
            </>
        );

        return (
            <>
                <Modal size="5xl" isOpen={isOpenAssingJobModel} onClose={onCloseAssingJobModel}>
                    <ModalOverlay/>
                    <ModalContent>

                        <ModalBody>
                            <ModelHeader/>
                            <Box>
                                <SimpleGrid columns={2} spacing={12}>
                                    <VStack>
                                        <Box alignSelf={"flex-start"}>
                                            <Button fontWeight={500} color={"#42526E"} mt={"5px"} variant="solid"
                                                    size="sm">
                                                Attach
                                            </Button>
                                        </Box>

                                        <Box fontWeight={500} w={"100%"} alignSelf={"flex-start"}>
                                            <Text fontSize="xs"> Description</Text>
                                            <Textarea/>
                                        </Box>

                                        <Box alignSelf={"flex-start"}>
                                            <Button size="sm" colorScheme="blue" mr={2}>
                                                Save
                                            </Button>
                                            <Button color={"#42526E"} size="sm" variant="solid"
                                                    onClick={onCloseAssingJobModel}>
                                                Cancel
                                            </Button>

                                        </Box>

                                        <Tabs>
                                            <TabList>
                                                <Tab
                                                    _focus={{
                                                        outline: "none !important",
                                                        boxShadow: "none !important",
                                                    }}
                                                >
                                                    <Text color={"#42526E"} fontWeight={"semibold"} fontSize="md">
                                                        Comments
                                                    </Text>
                                                </Tab>
                                                <Tab
                                                    _focus={{
                                                        outline: "none !important",
                                                        boxShadow: "none !important",
                                                    }}
                                                >
                                                    <Text color={"#42526E"} fontWeight={"semibold"} fontSize="md">
                                                        History
                                                    </Text>
                                                </Tab>
                                            </TabList>

                                            <TabPanels>
                                                <TabPanel>
                                                    <Flex>
                                                        <Text ps={1} fontSize={"11px"} fontWeight={"700"}
                                                              color={"#A6A6A6"}>TODAY</Text>
                                                    </Flex>
                                                    {comments}
                                                </TabPanel>
                                                <TabPanel>
                                                    <Flex>
                                                        <Text ps={1} fontSize={"11px"} fontWeight={"700"}
                                                              color={"#A6A6A6"}>TODAY</Text>
                                                    </Flex>
                                                    {comments}
                                                </TabPanel>
                                            </TabPanels>
                                        </Tabs>
                                    </VStack>
                                    <Box>
                                        <Card border bottomSpace>
                                            <Text p={2}>Details</Text>
                                            <Divider/>
                                            <Flex marginTop={"5px"} alignSelf={"self-start"} pt={1}>
                                                <Checkbox ps={3} defaultIsChecked fontWeight={"400"}
                                                          color={"#091E42"}>Internal</Checkbox>
                                                <Checkbox onChange={() => {
                                                    onOpenAssigneesModel()
                                                }} ps={5} fontWeight={"400"}
                                                          color={"#091E42"}>External</Checkbox>
                                            </Flex>
                                            <RichViewItem
                                                label={"Assignee"}
                                                avatar={"https://bit.ly/dan-abramov"}
                                                value={"Lorem ipsum"}
                                            />
                                            <RichViewItem label={"Due date"} value={"Dec 15 2021"}/>
                                            <RichViewItem label={"Note"} textarea/>
                                            <RichViewItem
                                                label={"Reporter"}
                                                avatar={"https://bit.ly/dan-abramov"}
                                                value={"Help desk - Dep of lorem"}
                                            />
                                            <RichViewItem
                                                label={"Reporter dep"}
                                                avatar={"https://bit.ly/dan-abramov"}
                                                value={"Department of lorem ipsum"}
                                            />
                                            <br/><br/><br/>
                                        </Card>
                                    </Box>
                                </SimpleGrid>
                            </Box>
                        </ModalBody>
                        <ModalFooter>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </>
        );
    }

    const AddUserPrivilegesModel = (props: any) => {

        return (
            <>
                <Modal size="lg" isOpen={isAddUserPrivilegesModel} onClose={onCloseAddUserPrivilegesModel} isCentered>
                    <ModalOverlay/>
                    <ModalContent>
                        <ModalHeader>User Privileges</ModalHeader>
                        <ModalBody>
                            <VStack spacing={0}>
                                <Box w={"100%"}>
                                    <HStack display={"flex"}>
                                        <FormControl>
                                            <FormLabel color={"gray.500"} fontSize={'sm'}>User Name</FormLabel>
                                            <Input
                                                size="sm"
                                                ref={initialRef}
                                                placeholder="Enter User Name"
                                                color={"gray.700"}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel color={"gray.500"} fontSize={'sm'}>Email</FormLabel>
                                            <Input size="sm" placeholder="Enter User Email" color={"gray.700"}/>
                                        </FormControl>
                                    </HStack>
                                </Box>
                                <Divider pt={3}/>
                                <Box Box w={"100%"}>
                                    <HStack spacing={6}>
                                        <Box>
                                            <FormControl>
                                                <HStack spacing={0}>
                                                    <FormLabel htmlFor="Entry Point" mb="0">
                                                        Entry Point
                                                    </FormLabel>
                                                    <Switch size="sm" id="entry-point"/>
                                                </HStack>
                                            </FormControl>
                                        </Box>

                                        <Box>
                                            <FormControl>
                                                <HStack spacing={0}>
                                                    <FormLabel FormLabel htmlFor="Entry Point" mb="0">
                                                        HOD
                                                    </FormLabel>
                                                    <Switch size="sm" id="entry-point"/>
                                                </HStack>
                                            </FormControl>
                                        </Box>
                                        <Box>
                                            <FormControl>
                                                <HStack spacing={0}>
                                                    <FormLabel FormLabel htmlFor="Entry Point" mb="0">
                                                        User
                                                    </FormLabel>
                                                    <Switch size="sm" id="entry-point"/>
                                                </HStack>
                                            </FormControl>
                                        </Box>
                                    </HStack>
                                </Box>
                            </VStack>
                            <Box mt={4}>
                                <Wrap
                                    spacing={2}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                >
                                    <WrapItem pr={3}>
                                        <Checkbox borderColor={'dodgerblue'}>Checkbox</Checkbox>
                                    </WrapItem>
                                    <WrapItem pr={3}>
                                        <Checkbox borderColor={'dodgerblue'}>Checkbox</Checkbox>
                                    </WrapItem>
                                    <WrapItem pr={3}>
                                        <Checkbox borderColor={'dodgerblue'}>Checkbox</Checkbox>
                                    </WrapItem>
                                    <WrapItem pr={3}>
                                        <Checkbox borderColor={'dodgerblue'}>Checkbox</Checkbox>
                                    </WrapItem>
                                    <WrapItem pr={3}>
                                        <Checkbox borderColor={'dodgerblue'}>Checkbox</Checkbox>
                                    </WrapItem>
                                    <WrapItem pr={3}>
                                        <Checkbox borderColor={'dodgerblue'}>Checkbox</Checkbox>
                                    </WrapItem>
                                    <WrapItem pr={3}>
                                        <Checkbox borderColor={'dodgerblue'}>Checkbox</Checkbox>
                                    </WrapItem>
                                    <WrapItem pr={3}>
                                        <Checkbox borderColor={'dodgerblue'}>Checkbox</Checkbox>
                                    </WrapItem>
                                    <WrapItem pr={3}>
                                        <Checkbox borderColor={'dodgerblue'}>Checkbox</Checkbox>
                                    </WrapItem>
                                    <WrapItem pr={3}>
                                        <Checkbox borderColor={'dodgerblue'}>Checkbox</Checkbox>
                                    </WrapItem>
                                    <WrapItem pr={3}>
                                        <Checkbox borderColor={'dodgerblue'}>Checkbox</Checkbox>
                                    </WrapItem>
                                    <WrapItem pr={3}>
                                        <Checkbox borderColor={'dodgerblue'}>Checkbox</Checkbox>
                                    </WrapItem>
                                </Wrap>
                            </Box>
                        </ModalBody>
                        <ModalFooter>
                            <Button size="sm" variant="solid" mr={3} onClick={onCloseAddUserPrivilegesModel}>
                                Cancel
                            </Button>
                            <Button size="sm" colorScheme="blue" mr={3}>
                                Confirm
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </>
        );
    };

    const AddNewUserModel = (props: any) => {

        return (
            <>
                <Modal size="xl" isOpen={isOpen} onClose={onClose} isCentered>
                    <ModalOverlay/>
                    <ModalContent>
                        <ModalHeader>Add New User</ModalHeader>
                        <ModalBody>
                            <Grid
                                templateRows="repeat(2, 1fr)"
                                templateColumns="repeat(2, 1fr)"
                                gap={4}
                            >
                                <GridItem>
                                    <FormControl>
                                        <FormLabel color={"gray.500"} fontSize={'sm'}>User Name</FormLabel>
                                        <Input
                                            size="sm"
                                            ref={initialRef}
                                            placeholder="Enter User Name"
                                            color={"gray.700"}
                                        />
                                    </FormControl>
                                </GridItem>
                                <GridItem>
                                    <FormControl>
                                        <FormLabel color={"gray.500"} fontSize={'sm'}>User Email</FormLabel>
                                        <Input size="sm" placeholder="Enter User Email" color={"gray.700"}/>
                                    </FormControl>
                                </GridItem>
                                <GridItem>
                                    <FormControl>
                                        <FormLabel color={"gray.500"} fontSize={'sm'}>User Departments</FormLabel>
                                        <SelectBox></SelectBox>
                                    </FormControl>
                                </GridItem>
                            </Grid>
                        </ModalBody>
                        <ModalFooter>
                            <Button size="sm" variant="solid" mr={3} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button size="sm" colorScheme="blue" mr={3}>
                                Confirm
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </>
        );
    };

    return (
        <>
            <PageHeader primaryActionName={"Add New User"} primaryAction={() => {
                onOpen()
            }} title={'Users'}/>
            <Box py={4}>
                <Stack
                    direction={'row'}
                    spacing={4}>
                    <InputGroup width={200} size={'sm'}>
                        <Input placeholder='Search'/>
                    </InputGroup>
                    <Menu>
                        <MenuButton size={'sm'} as={Button} rightIcon={<ChevronDownIcon/>}>
                            Categories
                        </MenuButton>
                        <MenuList>
                            <MenuItem>Category 1</MenuItem>
                            <MenuItem>Category 2</MenuItem>
                            <MenuItem>Category 3</MenuItem>
                            <MenuItem>Category 4</MenuItem>
                            <MenuItem>Category 5</MenuItem>
                        </MenuList>
                    </Menu>
                    <Menu size={'sm'}>
                        <MenuButton size={'sm'} as={Button} rightIcon={<ChevronDownIcon/>}>
                            Choices
                        </MenuButton>
                        <MenuList>
                            <MenuItem>Choice 1</MenuItem>
                            <MenuItem>Choice 2</MenuItem>
                            <MenuItem>Choice 3</MenuItem>
                            <MenuItem>Choice 4</MenuItem>
                            <MenuItem>Choice 5</MenuItem>
                        </MenuList>
                    </Menu>
                </Stack>
            </Box>
            <MainTable columnHeaders={column} actions={actions} rows={rows}/>
            <AddNewUserModel/>
            <AddUserPrivilegesModel/>
            <AssignJobModel/>
            <AssigneesModel/>
        </>
    )
}

export default UsersPage