import {Avatar, Badge, Box, Divider, Flex, HStack, Spacer, Text, VStack} from "@chakra-ui/react";
import {COLORS} from "../../../assets/theme/theme";

const OrgSummeryCard = () => {
    return (
        <Box shadow={"lg"} bg={COLORS.gray10} my={3} borderRadius={5} w={250} h={185} d={"flex"}
             justifyContent={"center"}
             alignItems={"center"} border={"1px"} borderColor={"blackAlpha.50"}>
            <VStack p={3} alignItems={"start"} w={"inherit"}>

                <HStack alignItems={"center"} justifyContent={"center"}>
                    <Avatar
                        size='md'
                        name='Prosper Otemuyiwa'
                        src='https://bit.ly/prosper-baba'
                    />
                    <VStack alignItems={"start"}>
                        <Text fontWeight={"medium"} fontSize={"md"}>Organization</Text>
                        <Badge colorScheme='blue'>DEPARTMENT</Badge>
                    </VStack>
                </HStack>

                <Divider/>

                <Flex w={"100%"}>
                    <Text fontSize={"sm"} fontWeight={"semibold"}>Open Projects</Text>
                    <Spacer/>
                    <Badge fontSize={15} colorScheme='blue'>8</Badge>
                </Flex>

                <Divider/>

                <Flex w={"100%"}>
                    <Text fontSize={"sm"} fontWeight={"semibold"}>Completed Projects</Text>
                    <Spacer/>
                    <Badge fontSize={15} colorScheme='green'>8</Badge>
                </Flex>

                <Divider/>
            </VStack>
        </Box>
    )
}

export default OrgSummeryCard;