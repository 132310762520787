import PageHeader from "../../components/page-header/page-header.component";
import {
    Avatar,
    Box,
    Grid,
    GridItem,
    Heading,
    HStack,
    Image,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    VStack
} from "@chakra-ui/react";
import ViewItem from "../../components/view-item/ViewItem";
import Card from "../../components/card/Card";
import ItemLine from "../../components/ItemLine/ItemLine";
import WorkFlowItem from "../../components/workflow-item/WorkFlowItem";
import AlertView from "../../components/alert-view/alert-view";

const header = (
    <>
        <PageHeader title={"DOC2021-12-01DEC"}/>
    </>
)

const gridItem1MarkUp = (
    <>
        <Stack mb={3} direction={"column"} alignItems={"start"}>
            <Avatar h={150} w={150} name='Segun Adebayo' src='https://bit.ly/sage-adebayo'/>
            <Heading fontWeight={"semibold"} size={"md"} pt={3}>Dasun Edirisinghe</Heading>
        </Stack>

        <Card padding border>
            <Card bottomSpace title={"about"}>
                <Box>
                    <ViewItem label={"Reg Number"} value={"2020-R-45784"}/>
                    <ViewItem label={"Name"} value={"Dasun Edirisinghe"}/>
                    <ViewItem label={"Full Name"} value={"Lorem Ipsum"}/>
                    <ViewItem label={"NIC"} value={"893130897V"}/>
                </Box>
            </Card>

            <Card bottomSpace title={"residence"}>
                <Box>
                    <ViewItem label={"Address"}
                              value={"Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus "}/>
                    <ViewItem label={"Address Line 1"} value={"Lorem Ipsum"}/>
                    <ViewItem label={"Address Line 2"} value={"Lorem Ipsum"}/>
                    <ViewItem label={"City"} value={"Colombo"}/>
                    <ViewItem label={"Postal Code"} value={"00130"}/>
                </Box>
            </Card>

            <Card title={"contacts"}>
                <Box>
                    <ViewItem label={"Phone Number"} value={"0778546201"}/>
                    <ViewItem label={"Home"} value={"011894462"}/>
                    <ViewItem label={"Email"} value={"sample@gmail.com"}/>
                </Box>
            </Card>

        </Card>

        <Card topSpace>
            <AlertView/>
            <AlertView/>
        </Card>
    </>
)

const gridItem2MarkUp = (
    <>
        <Card border padding heading={"General Information"}>
            <Box>
                <ViewItem onThird label={"Project Id"} value={"DOC2021-12-01DEC"}/>
                <ViewItem onThird label={"Received"} value={"01-12-2021"}/>
                <ViewItem onThird label={"Category"} value={"Innovation category"}/>
                <ViewItem onThird label={"Status"} badge={"green"} value={"Active"}/>
            </Box>
        </Card>

        <Card border padding topSpace heading={"Project Information"}>
            <Box>
                <ViewItem onThird label={"Project Id"} value={"DOC2021-12-01DEC"}/>
                <ViewItem onThird label={"Project title"}
                          value={"Lorem ipsum dolor sit amet, consectetur"}/>
                <ViewItem onThird label={"Description"}
                          value={"Suscipit lorem nisi placerat amet leo massa non consectetur eu a ultrices aenean consectetur ullamcorper laoreet iaculis sit malesuada orci leo convallis odio hendrerit pretium elementum quam varius eros, enim lacus sed eget adipiscing eu, nec magnis eget mollis libero in euismod ac id dictumst arcu magna"}/>
                <ViewItem onThird attachment label={"Attachments"}/>
            </Box>
        </Card>

        <Card border padding topSpace heading={"IP Protection"}>
            <Box>
                <ViewItem onThird label={"IP Id"} value={"78451E-2021-78ESR"}/>
                <ViewItem onThird label={"Approved Date"}
                          value={"11-10-2019"}/>
                <ViewItem onThird attachment label={"Attachments"}/>
            </Box>
        </Card>

        <Card border padding topSpace heading={"Awards"}>
            <HStack alignItems={"center"}>
                <Avatar
                    size='md'
                    name='Prosper Otemuyiwa'
                    src='https://bit.ly/prosper-baba'
                />
                <VStack alignItems={"start"}>
                    <Text fontWeight={"medium"} fontSize={"md"}>Award-1</Text>
                    <Text mt={"0px !important"} fontSize={"sm"}>Lorem ipsum dolor sit amet,
                        consectetur</Text>
                </VStack>
            </HStack>
            <Image shadow={"md"} objectFit='cover'
                   ms={"55px"}
                   mt={2}
                   borderRadius={5}
                   boxSize={120}
                   src='https://bit.ly/dan-abramov'
                   alt='Dan Abramov'/>
        </Card>
    </>
)

const gridItem3Markup = (
    <>
        <Card border padding heading={"Current Status"}>
            <Box>
                <ViewItem onThird label={"Organization"} value={"Lorem ipsum dolor organization"}/>
                <ViewItem onThird label={"Department"} value={"Cursus sed"}/>
                <ViewItem onThird label={"Asignee"} value={"Lorem Ipsum"}/>
            </Box>
        </Card>

        <Card border padding topSpace heading={"Official Documents"}>
            <Box>
                <ItemLine file title={"Registration-1"} subTitle={"Lorem ipsum dolor sit amet, consectetur"}
                          badged={"DEPARTMENT"}/>
                <ItemLine file title={"Registration-1"} subTitle={"Lorem ipsum dolor sit amet, consectetur"}
                          badged={"DEPARTMENT"}/>
                <ItemLine file title={"Registration-1"} subTitle={"Lorem ipsum dolor sit amet, consectetur"}
                          badged={"DEPARTMENT"}/>
                <ItemLine file title={"Registration-1"} subTitle={"Lorem ipsum dolor sit amet, consectetur"}
                          badged={"DEPARTMENT"}/>
                <ItemLine file title={"Registration-1"} subTitle={"Lorem ipsum dolor sit amet, consectetur"}
                          badged={"DEPARTMENT"}/>
                <ItemLine file title={"Registration-1"} subTitle={"Lorem ipsum dolor sit amet, consectetur"}
                          badged={"DEPARTMENT"}/>
            </Box>
        </Card>

        <Card border topSpace>
            <Tabs>
                <TabList>
                    <Tab _focus={{
                        outline: "none !important",
                        boxShadow: "none !important",
                    }} fontWeight={"semibold"} fontSize='md'>Tasks</Tab>
                    <Tab _focus={{
                        outline: "none !important",
                        boxShadow: "none !important",
                    }} fontWeight={"semibold"} fontSize='md'>Activity</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel>
                        <ItemLine file title={"Registration-1"}
                                  subTitle={"Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,"}
                                  badgeColor={"green"} badged={"ACTIVE"}/>
                        <ItemLine file title={"Registration-1"}
                                  subTitle={"molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum"}
                                  badgeColor={"green"} badged={"ACTIVE"}/>
                        <ItemLine file title={"Registration-1"}
                                  subTitle={"numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium"}
                                  badgeColor={"green"} badged={"ACTIVE"}/>
                        <ItemLine file title={"Registration-1"}
                                  subTitle={"optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis"}
                                  badgeColor={"green"} badged={"ACTIVE"}/>
                        <ItemLine file title={"Registration-1"}
                                  subTitle={"obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam"}
                                  badgeColor={"green"} badged={"ACTIVE"}/>
                        <ItemLine file title={"Registration-1"}
                                  subTitle={"nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit,"}
                                  badgeColor={"green"} badged={"ACTIVE"}/>
                    </TabPanel>
                    <TabPanel>
                        <WorkFlowItem/>
                        <WorkFlowItem/>
                        <WorkFlowItem/>
                        <WorkFlowItem/>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Card>
    </>
)

const DocumentView = () => {
    return (
        <>
            {header}

            <Box mt={2} h={'calc(100vh - 160px)'} overflowY={"auto"}>
                <Grid
                    h='calc(100vh - 157px)'
                    templateColumns='repeat(5, 1fr)'
                    gap={4}
                >
                    <GridItem colSpan={1}>
                        {gridItem1MarkUp}
                    </GridItem>
                    <GridItem colSpan={2}>
                        {gridItem2MarkUp}
                    </GridItem>
                    <GridItem colSpan={2}>
                        {gridItem3Markup}
                    </GridItem>
                </Grid>
            </Box>
        </>
    );
}

export default DocumentView;