import {Badge, Box, Flex, Image, SimpleGrid, Text} from "@chakra-ui/react";

interface IProps {
    label: string | number,
    value?: string | number,
    badge?: string,
    onHalf?: boolean,
    onThird?: boolean,
    attachment?: any
}

const ViewItem = (props: IProps) => {
    let {badge, value, label, onHalf = true, onThird, attachment} = props;

    const configs = {
        onHalf: {
            flex1: 0.5,
            flex2: 0.5
        },
        onThird: {
            flex1: 0.3,
            flex2: 0.7
        }
    }

    if (onThird) onHalf = false;

    return (
        <Flex mx={2} my={1}>
            <Box flex={onHalf ? configs.onHalf.flex1 : configs.onThird.flex1}>
                <Text color={"blackAlpha.700"} fontSize={'sm'}>{label}</Text>
            </Box>

            <Box flex={onHalf ? configs.onHalf.flex2 : configs.onThird.flex2}>
                {badge ? <Badge variant='solid' colorScheme={badge}>
                    {value}
                </Badge> : <Text color={"blackAlpha.800"} fontSize={'sm'}>{value}</Text>}

                {attachment ?
                    <Box>
                        <SimpleGrid minChildWidth='120px' spacing='40px' mb={8}>
                            <Box height='80px' my={3}>
                                <Image shadow={"md"} objectFit='cover' borderRadius={5} width={"100%"} height={"120px"}
                                       src='https://bit.ly/dan-abramov'
                                       alt='Dan Abramov'/>
                            </Box>
                            <Box height='80px' my={3}>
                                <Image shadow={"md"} objectFit='cover' borderRadius={5} width={"100%"} height={"120px"}
                                       src='https://bit.ly/dan-abramov'
                                       alt='Dan Abramov'/>
                            </Box>
                        </SimpleGrid>
                    </Box> : null}
            </Box>
        </Flex>
    )
}

export default ViewItem;