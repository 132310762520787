import {FormControl, FormLabel} from "@chakra-ui/react";

import DATA, {AssigneeDATA} from "./data";
import {OrganizationsDATA,DepartmentDATA} from "./data"
import {Select} from "chakra-react-select";

export const SelectBox = (props: any) => {
    return (
        <FormControl >
            <FormLabel>
                {props.label}
            </FormLabel>
            <Select
                size={"sm"}
                name={props.label}
                options={DATA}
                placeholder={props.placeholder}
                closeMenuOnSelect={true}

            />
        </FormControl>
    )
}

export const SelectBoxForOrganizations = (props:any)=>{
    return (
        <FormControl >
            <FormLabel>
                {props.label}
            </FormLabel>
            <Select
                size={"sm"}
                name={props.label}
                options={OrganizationsDATA}
                placeholder={props.placeholder}
                closeMenuOnSelect={true}

            />
        </FormControl>
    )
}

export const SelectBoxForDepartment = (props:any)=>{
    return (
        <FormControl >
            <FormLabel>
                {props.label}
            </FormLabel>
            <Select
                size={"sm"}
                name={props.label}
                options={DepartmentDATA}
                placeholder={props.placeholder}
                closeMenuOnSelect={true}

            />
        </FormControl>
    )
}
export const SelectBoxForAssignee= (props:any)=>{
    return (
        <FormControl >
            <FormLabel>
                {props.label}
            </FormLabel>
            <Select
                size={"sm"}
                name={props.label}
                options={AssigneeDATA}
                placeholder={props.placeholder}
                closeMenuOnSelect={true}
            />
        </FormControl>
    )
}
