import {useRoutes} from "react-router-dom";
import LoginPage from "../pages/login/login.page";
import HomePage from "../pages/home/home.page";
import MainLayout from "../layouts/main-layout/main.layout";
import ProfilePage from "../pages/profile/profile.page";
import TaskRoadMap from "../pages/task-roadmap/task-roadmap.page";
import DocumentWorkflowPage from "../pages/document-workflow/document-workflow.page";
import ProjectSummeryPage from "../pages/projects-summery/project-summery.page";
import DocumentView from "../pages/document-view/document-view.page";
import {MainPage} from "../pages/model/model-page";
import ApplicationViewPage from "../pages/application-view/application-view.page";
import AllApplicationsPage from "../pages/all-applications/all-applications.page";
import ReceivedApplicationsPage from "../pages/received-applications/received-applications.page";
import DepartmentsPage from "../pages/department/departments.page";
import OrganizationProfilePage from "../pages/organization-profile/organization-profile.page";
import TaskHistoryPage from "../pages/task-history/task-history.page";
import TodoTaskPage from "../pages/todo-task/todo-task.page";
import UsersPage from "../pages/user/users.page";

const RouterConfigs = () => {
    const element = useRoutes([
        {
            path: "/",
            element: <LoginPage/>,
        },
        {
            path: "/app",
            element: <MainLayout/>,
            children: [
                {index: true, element: <AllApplicationsPage/>},
                {
                    path: '/app/test',
                    element: <HomePage/>
                },
                {
                    path: 'task-roadmap',
                    element: <TaskRoadMap/>
                },
                {
                    path: 'workflow',
                    element: <DocumentWorkflowPage/>
                },
                {
                    path: 'project-summery',
                    element: <ProjectSummeryPage/>
                },
                {
                    path: 'document-view',
                    element: <DocumentView/>
                }
            ],
        },
        {
            path: "/all-application",
            element: <MainLayout/>,
            children: [
                {index: true, element: <AllApplicationsPage/>}
            ],
        },
        {
            path: "/received-application",
            element: <MainLayout/>,
            children: [
                {index: true, element: <ReceivedApplicationsPage/>}
            ],
        },
        {
            path: "/task",
            element: <MainLayout/>,
            children: [
                {index: true, element: <TodoTaskPage/>},
                {path: "/task/task-history", element: <TaskHistoryPage/>}
            ],
        },
        {
            path: "/profile",
            element: <MainLayout/>,
            children: [
                {index: true, element: <ProfilePage/>}
            ],
        },
        {
            path: "/organization",
            element: <MainLayout/>,
            children: [
                {index: true, element: <OrganizationProfilePage/>}
            ],
        },
        {
            path: "/department",
            element: <MainLayout/>,
            children: [
                {index: true, element: <DepartmentsPage/>}
            ],
        },
        {
            path: "/user",
            element: <MainLayout/>,
            children: [
                {index: true, element: <UsersPage/>}
            ],
        },
        {
            path: "/model",
            element: <MainLayout/>,
            children: [
             {index: true, element: <MainPage/>}
             ],
        },
        {
            path: "/application-view",
            element: <MainLayout/>,
            children: [
                {index: true, element: <ApplicationViewPage/>}
            ],
        },
        {path: "*", element: <h1>page not found</h1>},
    ]);

    return element;
};

export default RouterConfigs;