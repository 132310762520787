export const colourOptions = [
    {value: "IT", label: "IT", color: "#0052CC"},
    {value: "Education", label: "Education", color: "#5243AA"},
    {value: "Management", label: "Management", color: "#FF5630"},
    {value: "Engineering", label: "Engineering", color: "#FF8B00"},
    {value: "Technology", label: "Technology", color: "#FFC400"},
    {value: "Science", label: "Science", color: "#36B37E"}
];

export const Organizations = [
    {value: "IT", label: "IT", color: "#0052CC"},
    {value: "Education", label: "Education", color: "#5243AA"},
    {value: "Management", label: "Management", color: "#FF5630"},
    {value: "Engineering", label: "Engineering", color: "#FF8B00"},
    {value: "Technology", label: "Technology", color: "#FFC400"},
    {value: "Science", label: "Science", color: "#36B37E"}
];
export const Department = [
    {value: "IT", label: "IT", color: "#0052CC"},
    {value: "Education", label: "Education", color: "#5243AA"},
    {value: "Management", label: "Management", color: "#FF5630"},
    {value: "Engineering", label: "Engineering", color: "#FF8B00"},
    {value: "Technology", label: "Technology", color: "#FFC400"},
    {value: "Science", label: "Science", color: "#36B37E"}
];
export const Assignee = [
    {value: "IT", label: "IT", color: "#0052CC"},
    {value: "Education", label: "Education", color: "#5243AA"},
    {value: "Management", label: "Management", color: "#FF5630"},
    {value: "Engineering", label: "Engineering", color: "#FF8B00"},
    {value: "Technology", label: "Technology", color: "#FFC400"},
    {value: "Science", label: "Science", color: "#36B37E"}
];

// export const flavourOptions = [
//     {value: "vanilla", label: "Vanilla", rating: "safe"},
//     {value: "chocolate", label: "Chocolate", rating: "good"},
//     {value: "strawberry", label: "Strawberry", rating: "wild"},
//     {value: "salted-caramel", label: "Salted Caramel", rating: "crazy"}
// ];

export const DATA = [
    {
        label: "Departments",
        options: colourOptions
    },
    // {
    //     label: "Flavours",
    //     options: flavourOptions
    // }
];

export const OrganizationsDATA = [
    {
        label: "Organizations",
        options: Organizations
    },

];

export const AssigneeDATA = [
    {
        label: "Assignee",
        options: Assignee
    },

]

export const DepartmentDATA = [
    {
        label: "Department",
        options: Department
    },

];

export default DATA;
