import {Alert, AlertDescription, AlertIcon, AlertTitle, Box, CloseButton} from "@chakra-ui/react";

const AlertView = () => {
    return (
        <Alert status='success' mb={4}>
            <AlertIcon/>
            <Box flex='1'>
                <AlertTitle>Success!</AlertTitle>
                <AlertDescription display='block'>
                    Your application has been received.
                </AlertDescription>
            </Box>
            <CloseButton position='absolute' right='8px' top='8px'/>
        </Alert>
    )
}
export default AlertView;