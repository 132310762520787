import {
    Avatar,
    Badge,
    Box,
    Divider,
    Flex,
    Heading,
    HStack,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Spacer,
    Text,
    VStack
} from "@chakra-ui/react";
import {ChevronDownIcon} from "@chakra-ui/icons";
import {useNavigate} from "react-router-dom";

const ProjectSummeryItem = () => {

    const navigate = useNavigate()

    return (
        <Box shadow={"lg"} borderRadius={5} w={250} h={185} border={"1px"} borderColor={"blackAlpha.50"}>
            <VStack p={3} alignItems={"start"}>
                <HStack alignItems={"center"}>
                    <Avatar
                        size='sm'
                        name='Prosper Otemuyiwa'
                        src='https://bit.ly/prosper-baba'
                    />
                    <Text noOfLines={2} lineHeight={1.5} fontWeight={"medium"} fontSize={"sm"}>Morbi vestibulum,sdsds
                        adhuid
                        uwdhhid lorem senectus et semper</Text>
                </HStack>

                <Heading color={"blackAlpha.600"} fontWeight={"medium"} textTransform={"uppercase"} fontSize={"13px"}>quick
                    links</Heading>

                <Flex w={"100%"}>
                    <Text fontSize={"sm"}>Open tasks</Text>
                    <Spacer/>
                    <Badge colorScheme='blue'>8</Badge>
                </Flex>

                <Flex w={"100%"}>
                    <Text fontSize={"sm"}>Completed Tasks</Text>
                    <Spacer/>
                    <Badge colorScheme='green'>8</Badge>
                </Flex>

                <Divider/>

                <Menu>
                    <MenuButton>
                        <HStack>
                            <Text fontSize={"sm"}>Board</Text>
                            <ChevronDownIcon/>
                        </HStack>
                    </MenuButton>
                    <MenuList>
                        <MenuItem onClick={() => {
                            navigate("/app/task-roadmap")
                        }}>Roadmap</MenuItem>
                        <MenuItem onClick={() => {
                            navigate("/app/workflow")
                        }}>Workflow</MenuItem>
                    </MenuList>
                </Menu>
            </VStack>
        </Box>
    )
}
export default ProjectSummeryItem;