import {Avatar, Badge, Box, Flex, HStack, Text, Textarea} from "@chakra-ui/react";

interface IProps {
    label: string | number,
    value?: string | number,
    badge?: string,
    avatar?: string,
    attachment?: any,
    textarea?: boolean,
}

const RichViewItem = (props: IProps) => {
    let {badge, value, label, avatar, textarea} = props;

    return (
        <Flex mx={2} my={3}>
            <Box flex={0.3}>
                <Text fontWeight={400} color={"#404040"}>{label}</Text>
            </Box>

            <Box flex={0.7}>
                <HStack>
                    {avatar ? <Avatar size='xs' name='Dan Abrahmov'
                                      src={avatar ? avatar : "https://bit.ly/dan-abramov"}/> : null}

                    {badge ? <Badge variant='solid' colorScheme={badge}>
                        {value}
                    </Badge> : <Text fontWeight={400}  color={"#404040"}>{value}</Text>}

                    {textarea ? <Textarea placeholder={"Note"}/> : null}
                </HStack>
            </Box>
        </Flex>
    )
}

export default RichViewItem;