import React from 'react';
import {ChakraProvider, extendTheme} from '@chakra-ui/react'
import './App.css';
import {BrowserRouter} from "react-router-dom";
import RouterConfigs from "./routes/router.configs";

import {COLORS} from "./assets/theme/theme";

const theme = extendTheme({
    components: {
        Button: {
            baseStyle: {
                _focus: {
                    outline: "none !important",
                    boxShadow: "none !important",
                },
            },

            variants: {
                primary: {
                    backgroundColor: `${COLORS.primaryBlue} !important`,
                    color: COLORS.white,
                    _hover: {
                        backgroundColor: `${COLORS.primaryBlue50} !important`
                    }
                },
            },
        },
    }
})

function App({Component}: any) {
    return (
        <ChakraProvider theme={theme}>
            <BrowserRouter>
                <RouterConfigs/>
            </BrowserRouter>
        </ChakraProvider>
    )
}

export default App;
