import {Box, Button, Input, InputGroup, Menu, MenuButton, MenuItem, MenuList, Stack, Badge} from "@chakra-ui/react";
import {ChevronDownIcon} from "@chakra-ui/icons";
import PageHeader from "../../components/page-header/page-header.component";
import MainTable from "../../components/main-table/main-table.component";

const ReceivedApplicationsPage = () => {

    const column = ['Project','Innovator Name','Category','Current Status','IP Protection','date','Action']

    const rows = [['Innova','Dasun Edirisinghe ','Technology',<Badge colorScheme={'yellow'}>On going</Badge>,'yes','2021/05/03'],
        ['project1','Charith Jayakantha','Medical',<Badge colorScheme={'green'}>Complete</Badge>,'yes','2013/06/23'],
        ['project2','Tushan Herath','Science',<Badge colorScheme={'yellow'}>On going</Badge>,'yes','2021/05/30']]

    const actions = [{value:'a',label:'A'},{value:'b',label:'B'},{value:'c',label:'C'},{value:'d',label:'D'}]

    return (
        <>
            <PageHeader title={'Received Applications'}/>
            <Box py={4}>
                <Stack
                    direction={'row'}
                    spacing={4}>
                    <InputGroup width={200} size={'sm'}>
                        <Input placeholder='small size'/>
                    </InputGroup>
                    <Menu>
                        <MenuButton size={'sm'} as={Button} rightIcon={<ChevronDownIcon/>}>
                            Actions
                        </MenuButton>
                        <MenuList>
                            <MenuItem>Download</MenuItem>
                            <MenuItem>Create a Copy</MenuItem>
                            <MenuItem>Mark as Draft</MenuItem>
                            <MenuItem>Delete</MenuItem>
                            <MenuItem>Attend a Workshop</MenuItem>
                        </MenuList>
                    </Menu>
                    <Menu size={'sm'}>
                        <MenuButton size={'sm'} as={Button} rightIcon={<ChevronDownIcon/>}>
                            Actions
                        </MenuButton>
                        <MenuList>
                            <MenuItem>Download</MenuItem>
                            <MenuItem>Create a Copy</MenuItem>
                            <MenuItem>Mark as Draft</MenuItem>
                            <MenuItem>Delete</MenuItem>
                            <MenuItem>Attend a Workshop</MenuItem>
                        </MenuList>
                    </Menu>
                </Stack>
            </Box>
            <MainTable columnHeaders = {column} actions={actions} rows={rows} />
        </>
    )
}

export default ReceivedApplicationsPage