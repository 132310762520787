import {COLORS} from "../../../assets/theme/theme";
import {Box, Text} from "@chakra-ui/react";

const RoadMapItemContainer = ({children, title}: any) => {
    return (
        <>
            <Box bg={COLORS.gray20} height={`calc(100vh - 213px)`}>
                <Text mx={3} my={2} color={"blackAlpha.500"} fontSize='md'>{title}</Text>
                <Box height={`calc(100vh - 250px)`} scrollBehavior={"smooth"} overflowY={"auto"}>
                    {children}
                </Box>
            </Box>
        </>
    )
}

export default RoadMapItemContainer;