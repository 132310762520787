import {
    Box,
    Button, FormControl, FormLabel, Grid, GridItem,
    Input,
    InputGroup,
    Menu,
    MenuButton,
    MenuItem,
    MenuList, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay,
    Stack,
    useDisclosure
} from "@chakra-ui/react";
import {ChevronDownIcon} from "@chakra-ui/icons";
import React from "react";
import {useNavigate} from "react-router-dom";
import PageHeader from "../../components/page-header/page-header.component";
import MainTable from "../../components/main-table/main-table.component";

const DepartmentsPage = () => {

    const initialRef = React.useRef<any>(null);
    const {isOpen, onOpen, onClose} = useDisclosure();
    const finalRef = React.useRef<any>(null);

    const navigate = useNavigate()

    const column = ['Department', 'Head', 'Employees', 'Entry Point', 'Actions']
    const rows = [['Department 1', 'Lorem Ipsum', '15', 'Lorem Ipsum'],
        ['Department 2', 'Lorem Ipsum', '15', 'Lorem Ipsum'],
        ['Department 3', 'Lorem Ipsum', '15', 'Lorem Ipsum']]

    const actions = [
        {
            value: 'a',
            label: 'View',
            onPress: () => {
                navigate("")
            }
        },
        {
            value: 'b',
            label: 'Add User',
            onPress: () => {
                navigate("")
            }
        }]

    const AddNewDepartmentModel = () => {

        return (<Modal size="lg" isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>Add New Department</ModalHeader>
                <ModalBody>
                    <Grid
                        templateRows="repeat(1, 1fr)"
                        templateColumns="repeat(2, 1fr)"
                        gap={4}
                    >
                        <GridItem>
                            <FormControl>
                                <FormLabel color={"gray.500"} fontSize={'sm'}>Department Name</FormLabel>
                                <Input
                                    size="sm"
                                    ref={initialRef}
                                    placeholder="Enter Department Name"
                                    color={"gray.700"}
                                />
                            </FormControl>
                        </GridItem>
                        <GridItem>
                            <FormControl>
                                <FormLabel color={"gray.500"} fontSize={'sm'}>Remark</FormLabel>
                                <Input size="sm" placeholder="Enter Remark" color={"gray.700"}/>
                            </FormControl>
                        </GridItem>
                    </Grid>
                </ModalBody>
                <ModalFooter>
                    <Button variant="solid" mr={3} onClick={onClose} size="sm">
                        Cancel
                    </Button>
                    <Button colorScheme="blue" mr={3} size="sm">
                        Confirm
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>)
    }

    return (
        <>
            <PageHeader primaryActionName="Add New Department" primaryAction={() => {
                onOpen()
            }} title={'Departments'}/>
            <Box py={4}>
                <Stack
                    direction={'row'}
                    spacing={4}>
                    <InputGroup width={200} size={'sm'}>
                        <Input placeholder='Search'/>
                    </InputGroup>
                    <Menu>
                        <MenuButton size={'sm'} as={Button} rightIcon={<ChevronDownIcon/>}>
                            Categories
                        </MenuButton>
                        <MenuList>
                            <MenuItem>Category 1</MenuItem>
                            <MenuItem>Category 2</MenuItem>
                            <MenuItem>Category 3</MenuItem>
                            <MenuItem>Category 4</MenuItem>
                            <MenuItem>Category 5</MenuItem>
                        </MenuList>
                    </Menu>
                    <Menu size={'sm'}>
                        <MenuButton size={'sm'} as={Button} rightIcon={<ChevronDownIcon/>}>
                            Choices
                        </MenuButton>
                        <MenuList>
                            <MenuItem>Choice 1</MenuItem>
                            <MenuItem>Choice 2</MenuItem>
                            <MenuItem>Choice 3</MenuItem>
                            <MenuItem>Choice 4</MenuItem>
                            <MenuItem>Choice 5</MenuItem>
                        </MenuList>
                    </Menu>
                </Stack>
            </Box>
            <MainTable columnHeaders={column} actions={actions} rows={rows}/>

            <AddNewDepartmentModel/>

        </>
    )
}

export default DepartmentsPage