import {
    Box,
    Image,
    Grid,
    GridItem,
    Text,
    Stack, Heading,
} from '@chakra-ui/react'
import {Avatar} from '@chakra-ui/react'
import WorkFlowItem from "../../components/workflow-item/WorkFlowItem";
import ViewItem from "../../components/view-item/ViewItem";
import ItemLine from "../../components/ItemLine/ItemLine";
import Card from "../../components/card/Card";

const ProfilePage = () => {
    return (
        <>

            <Image objectFit='cover' width='100%' height='60' src='https://bit.ly/2Z4KKcF' alt='Dan Abramov'/>
            <Box>
                <Grid
                    h='200px'
                    templateRows='repeat(1, 1fr)'
                    templateColumns='repeat(11, 1fr)'
                    gap={4}>
                    <GridItem colSpan={2}></GridItem>
                    <GridItem colSpan={3} marginTop="-90px">
                        <Stack mb={3} direction={"column"} alignItems={"start"}>
                            <Avatar h={150} w={150} name='Segun Adebayo' src='https://bit.ly/kent-c-dodds'/>
                            <Heading fontWeight={"semibold"} size={"md"} pt={3}>Dasun Edirisinghe</Heading>
                        </Stack>

                        <Card border editable padding>
                            <Card bottomSpace title={"about"}>

                                <ViewItem label="Registration no" value="123568" onHalf></ViewItem>
                                <ViewItem label="Name" value="123568" onHalf></ViewItem>
                                <ViewItem label="Full name" value="dkdj jdkkdkd dkddddasd" onHalf></ViewItem>
                                <ViewItem label="NIC" value="2568569552" onHalf></ViewItem>

                            </Card>
                            <Card bottomSpace title={"residence"}>

                                <ViewItem label="Address" value="Lorem ipsum dolor sit amet, consectetur adipiscing elit ut*
                                          aliquam, purus sit amet luctus" onHalf></ViewItem>
                                <ViewItem label="Address line1" value="Lorem Ipsum" onHalf></ViewItem>
                                <ViewItem label="Address line2" value="Lorem Ipsum" onHalf></ViewItem>
                                <ViewItem label="City" value=" Lorem Ipsum" ></ViewItem>
                                <ViewItem label="Postal code" value="90542" ></ViewItem>
                            </Card>
                            <Card title={"Contact"}>

                                <ViewItem label="Phone Number" value="0778899556" onHalf></ViewItem>
                                <ViewItem label="Home" value="0348515121" onHalf></ViewItem>
                                <ViewItem label=" Email" value="example@gmail.com" onHalf></ViewItem>

                            </Card>
                        </Card>
                    </GridItem>
                    <GridItem colSpan={4}>
                        <Card padding>
                            <Card heading={"Applications"} topSpace>
                                <Box pl={4} border="1px" borderColor="#E8E8E8" py={2}
                                     
                                     overflowX="hidden">
                                    <ItemLine file title={"DOC2021/04/30/DOCAB"}
                                              subTitle={"lorim ipsum doter sit"}
                                    />
                                    <ItemLine file title={"DOC2021/04/30/DOCAB"}
                                              subTitle={"lorim ipsum doter sit"}
                                    />
                                    <ItemLine file title={"DOC2021/04/30/DOCAB"}
                                              subTitle={"lorim ipsum doter sit"}
                                    />

                                </Box>
                            </Card>

                            <Card heading={"Activity"} topSpace>
                                <Box paddingLeft="20px" border="1px" borderColor="#E8E8E8" paddingTop="10px" height="467px" maxHeight="467px"
                                     overflow="scroll"
                                     overflowX="hidden">
                                    <Text fontSize='md'>TODAY</Text>
                                        <WorkFlowItem/>
                                        <WorkFlowItem/>
                                        <WorkFlowItem/>
                                        <WorkFlowItem/>
                                    <Text fontSize='md'>YESTERDAY</Text>
                                        <WorkFlowItem/>
                                        <WorkFlowItem/>
                                        <WorkFlowItem/>
                                </Box>
                            </Card>
                        </Card>
                    </GridItem>
                    <GridItem colSpan={2}></GridItem>
                </Grid>
            </Box>
        </>


    )
}

export default ProfilePage