import {Avatar, Badge, HStack, Image, Text, VStack} from "@chakra-ui/react";
import logo from '../../assets/images/file.png';

interface IProps {
    title: string | number,
    subTitle?: string | number,
    badged?: string | number
    badgeColor?: string,
    file?: boolean,
    avatar?: string,
}

const ItemLine = (props: IProps) => {
    const {title, subTitle, badged, badgeColor = "yellow", file, avatar = 'https://bit.ly/dan-abramov'} = props;
    return (
        <HStack my={2} alignItems={"center"} justifyContent={"space-between"}>
            <HStack>
                {!file ? <Avatar
                    size='md'
                    name='Prosper Otemuyiwa'
                    src={avatar}
                /> : <Image boxSize={10} src={logo}/>}
                <VStack alignItems={"start"}>
                    <Text fontWeight={"medium"} fontSize={"md"}>{title}</Text>
                    <Text mt={"0px !important"} fontSize={"sm"}>{subTitle}</Text>
                </VStack>
            </HStack>

            {badged ? <Badge colorScheme={badgeColor}>{badged}</Badge> : null}
        </HStack>
    )
}

export default ItemLine;