import {Avatar, Badge, Box, HStack, Text, VStack, Wrap, WrapItem} from "@chakra-ui/react";
import {IoArrowForward} from "react-icons/io5";

const WorkFlowItem = (props: any) => {
    const {title = "Heading"} = props;
    return (<Box my={2}>
        <HStack alignItems={"flex-start"}>
            <Avatar
                size='md'
                name='Prosper Otemuyiwa'
                src='https://bit.ly/prosper-baba'
            />
            <VStack alignItems={"start"} pt={0.5}>
                <Wrap>
                    <WrapItem>
                        <Text fontWeight={"medium"} fontSize={"md"}>{title}</Text>
                    </WrapItem>

                    <WrapItem mt={"0px !important"} alignItems={"center"}>
                        <HStack>
                            <Badge colorScheme='blackAlpha'>Branch 1</Badge>
                            <IoArrowForward/>
                            <Badge colorScheme='blackAlpha'>Branch 2</Badge>
                        </HStack>
                    </WrapItem>
                </Wrap>
                <Text mt={"4px !important"} fontSize={"sm"}>Jane Rotanson</Text>
            </VStack>
        </HStack>
    </Box>)
}

export default WorkFlowItem;