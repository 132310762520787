import {Badge, Box, Button, Input, InputGroup, Menu, MenuButton, MenuItem, MenuList, Stack} from "@chakra-ui/react";
import {ChevronDownIcon} from "@chakra-ui/icons";
import {useNavigate} from "react-router-dom";
import PageHeader from "../../components/page-header/page-header.component";
import MainTable from "../../components/main-table/main-table.component";

const AllApplicationsPage = () => {

    const navigate = useNavigate()

    const column = ['Project', 'Status', 'Project ID', 'Assignee', 'Department', 'Organization', 'Action']
    const rows = [['Innova',
        <Badge colorScheme='green'>Active</Badge>, '0001 2565 2356', 'Kamal Nandasena', 'Technology', 'University'],
        ['project1',
            <Badge colorScheme='blue'>Closed</Badge>, '0225 026 0256', 'Varuna Dangalla', 'Medical', 'University'],
        ['project2', <Badge
            colorScheme='orange'>Pending</Badge>, '2136 5896 2325', 'Bandula Weerasiri', 'Science', 'University']]
    const actions = [
        {
            value: 'a',
            label: 'Task Roadmap',
            onPress: () => {
                navigate("/app/task-roadmap")
            }
        },
        {
            value: 'b',
            label: 'WorkFlow',
            onPress: () => {
                navigate("/app/workflow")
            }
        },
        {
            value: 'c',
            label: 'Document',
            onPress: () => {
                navigate("/app/document-view")
            }
        }, {
            value: 'd',
            label: 'Dashboard',
            onPress: () => {
            }
        }]
    return (
        <>
            <PageHeader title={'All Applications'}/>
            <Box py={4}>
                <Stack
                    direction={'row'}
                    spacing={4}>
                    <InputGroup width={200} size={'sm'}>
                        <Input placeholder='small size'/>
                    </InputGroup>
                    <Menu>
                        <MenuButton size={'sm'} as={Button} rightIcon={<ChevronDownIcon/>}>
                            Actions
                        </MenuButton>
                        <MenuList>
                            <MenuItem>Download</MenuItem>
                            <MenuItem>Create a Copy</MenuItem>
                            <MenuItem>Mark as Draft</MenuItem>
                            <MenuItem>Delete</MenuItem>
                            <MenuItem>Attend a Workshop</MenuItem>
                        </MenuList>
                    </Menu>
                    <Menu size={'sm'}>
                        <MenuButton size={'sm'} as={Button} rightIcon={<ChevronDownIcon/>}>
                            Actions
                        </MenuButton>
                        <MenuList>
                            <MenuItem>Download</MenuItem>
                            <MenuItem>Create a Copy</MenuItem>
                            <MenuItem>Mark as Draft</MenuItem>
                            <MenuItem>Delete</MenuItem>
                            <MenuItem>Attend a Workshop</MenuItem>
                        </MenuList>
                    </Menu>
                </Stack>
            </Box>
            <MainTable columnHeaders={column} actions={actions} rows={rows}/>
        </>
    )
}

export default AllApplicationsPage