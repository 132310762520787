import PageHeader from "../../components/page-header/page-header.component";
import {Badge, Box, Button, Input, InputGroup, Menu, MenuButton, MenuItem, MenuList, Stack} from "@chakra-ui/react";
import {ChevronDownIcon} from "@chakra-ui/icons";
import MainTable from "../../components/main-table/main-table.component";

const TodoTaskPage = () => {

    const column = ['Project','Status','Project ID','Assignee','Department','Reporter Dip','Reporter Org','Actions']

    const rows = [['Innova',<Badge colorScheme='green'>Active</Badge>,'0001 2565 2356','Kamal Nandasena','Techknowlogy','Techknowlogy','Organization 1'],
        ['project1',<Badge colorScheme='green'>Active</Badge>,'0225 026 0256','Varuna dangalla','Medical','Medical','Organization 2'],
        ['project2',<Badge colorScheme='orange'>Pending</Badge>,'2136 5896 2325','Nimal Siripala','Science','Engineering','Organization 3']]

    const actions = [{value:'a',label:'A'},{value:'b',label:'B'},{value:'c',label:'C'},{value:'d',label:'D'}]

    return (
        <>
            <PageHeader title={'Todo Tasks'}/>
            <Box py={4}>
                <Stack
                    direction={'row'}
                    spacing={4}>
                    <InputGroup width={200} size={'sm'}>
                        <Input placeholder='small size'/>
                    </InputGroup>
                    <Menu>
                        <MenuButton size={'sm'} as={Button} rightIcon={<ChevronDownIcon/>}>
                            Categories
                        </MenuButton>
                        <MenuList>
                            <MenuItem>Category 1</MenuItem>
                            <MenuItem>Category 2</MenuItem>
                            <MenuItem>Category 3</MenuItem>
                            <MenuItem>Category 4</MenuItem>
                            <MenuItem>Category 5</MenuItem>
                        </MenuList>
                    </Menu>
                    <Menu size={'sm'}>
                        <MenuButton size={'sm'} as={Button} rightIcon={<ChevronDownIcon/>}>
                            Choices
                        </MenuButton>
                        <MenuList>
                            <MenuItem>Choice 1</MenuItem>
                            <MenuItem>Choice 2</MenuItem>
                            <MenuItem>Choice 3</MenuItem>
                            <MenuItem>Choice 4</MenuItem>
                            <MenuItem>Choice 5</MenuItem>
                        </MenuList>
                    </Menu>
                </Stack>
            </Box>
            <MainTable columnHeaders = {column} actions={actions} rows={rows} />
        </>
    )
}

export default TodoTaskPage