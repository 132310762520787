import React from "react";
import {
    Image,
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    HStack,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    SimpleGrid,
    Switch,
    Text,
    Textarea,
    useDisclosure,
    VStack,
    Wrap,
    WrapItem,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Flex, Stack,
    Link,
} from "@chakra-ui/react";
import {BiLike, BiLockOpen, BiShareAlt, BiDotsHorizontalRounded} from "react-icons/bi";
import {
    AttachmentIcon,
    ChevronDownIcon,
    CloseIcon,
} from "@chakra-ui/icons";

import {
    SelectBoxForDepartment,
    SelectBoxForOrganizations,
    SelectBoxForAssignee,
    SelectBox
} from "./components/searchable-select-for-model/select-box";
import RichViewItem from "../../components/rich-view-item/rich-view-item";
import WorkFlowItem from "../../components/workflow-item/WorkFlowItem";
import priorityIcon from "../../assets/images/priority_icon.png";
import Card from "../../components/card/Card";
import {BsEyeFill} from "react-icons/bs";


const AddDepartmentModel = () => {

    const initialRef = React.useRef<any>(null);
    const {isOpen, onOpen, onClose} = useDisclosure();
    const finalRef = React.useRef<any>(null);

    return (
        <>
            <Button size="sm" onClick={onOpen}>
                Add New Department Model
            </Button>

            <Modal size="lg" isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Add New Department</ModalHeader>
                    <ModalBody>
                        <Grid
                            templateRows="repeat(1, 1fr)"
                            templateColumns="repeat(2, 1fr)"
                            gap={4}
                        >
                            <GridItem>
                                <FormControl>
                                    <FormLabel color={"gray.500"} fontSize={'sm'}>Department Name</FormLabel>
                                    <Input
                                        size="sm"
                                        ref={initialRef}
                                        placeholder="Enter Department Name"
                                        color={"gray.700"}
                                    />
                                </FormControl>
                            </GridItem>
                            <GridItem>
                                <FormControl>
                                    <FormLabel color={"gray.500"} fontSize={'sm'}>Remark</FormLabel>
                                    <Input size="sm" placeholder="Enter Remark" color={"gray.700"}/>
                                </FormControl>
                            </GridItem>
                        </Grid>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="solid" mr={3} onClick={onClose} size="sm">
                            Cancel
                        </Button>
                        <Button colorScheme="blue" size="sm">
                            Confirm
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

const ExternalAssigneesModel = () => {

    const initialRef = React.useRef<any>(null);
    const {isOpen, onOpen, onClose} = useDisclosure();
    const finalRef = React.useRef<any>(null);

    return (
        <>
            <Button size="sm" onClick={onOpen}>
                ExternalAssigneesModel
            </Button>

            <Modal size="3xl" isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader fontSize={"20px"}>External assignees</ModalHeader>
                    <ModalBody>
                        <SimpleGrid
                            columns={3}
                            spacing='40px'
                        >
                            <Box>
                                <FormControl>
                                    <FormLabel color={"gray.500"} fontSize={'sm'}>Organizations</FormLabel>
                                    <SelectBoxForOrganizations placeholder={"Select Organizations "}/>
                                </FormControl>
                            </Box>
                            <Box>
                                <FormControl>
                                    <FormLabel color={"gray.500"} fontSize={'sm'}>Departments</FormLabel>
                                    <SelectBoxForDepartment placeholder={"Select Departments "}/>
                                </FormControl>
                            </Box>
                            <Box>
                                <FormControl>
                                    <FormLabel color={"gray.500"} fontSize={'sm'}>Assignee</FormLabel>
                                    <SelectBoxForAssignee placeholder={"Select Assignee "}/>
                                </FormControl>
                            </Box>
                        </SimpleGrid>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="solid" mr={3} onClick={onClose} size="sm">
                            Cancel
                        </Button>
                        <Button colorScheme="blue" size="sm">
                            Confirm
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

const NewUserModel = (props: any) => {

    const initialRef = React.useRef<any>(null);
    const {isOpen, onOpen, onClose} = useDisclosure();
    const finalRef = React.useRef<any>(null);

    return (
        <>
            <Button size="sm" onClick={onOpen}>
                Add New User Model
            </Button>

            <Modal size="xl" isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Add New User</ModalHeader>
                    <ModalBody>
                        <Grid
                            templateRows="repeat(2, 1fr)"
                            templateColumns="repeat(2, 1fr)"
                            gap={4}
                        >
                            <GridItem>
                                <FormControl>
                                    <FormLabel color={"gray.500"} fontSize={'sm'}>User Name</FormLabel>
                                    <Input
                                        size="sm"
                                        ref={initialRef}
                                        placeholder="Enter User Name"
                                        color={"gray.700"}
                                    />
                                </FormControl>
                            </GridItem>
                            <GridItem>
                                <FormControl>
                                    <FormLabel color={"gray.500"} fontSize={'sm'}>User Email</FormLabel>
                                    <Input size="sm" placeholder="Enter User Email" color={"gray.700"}/>
                                </FormControl>
                            </GridItem>
                            <GridItem>
                                <FormControl>
                                    <FormLabel color={"gray.500"} fontSize={'sm'}>User Departments</FormLabel>
                                    <SelectBox></SelectBox>
                                </FormControl>
                            </GridItem>
                        </Grid>
                    </ModalBody>
                    <ModalFooter>
                        <Button size="sm" variant="solid" mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button size="sm" colorScheme="blue">
                            Confirm
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

const UserPrivilegesModel = (props: any) => {

    const initialRef = React.useRef<any>(null);
    const {isOpen, onOpen, onClose} = useDisclosure();
    const finalRef = React.useRef<any>(null);

    return (
        <>
            <Button size="sm" onClick={onOpen}>
                Set User Privileges Model
            </Button>

            <Modal size="lg" isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>User Privileges</ModalHeader>
                    <ModalBody>
                        <VStack spacing={0}>
                            <Box w={"100%"}>
                                <HStack display={"flex"}>
                                    <FormControl>
                                        <FormLabel color={"gray.500"} fontSize={'sm'}>User Name</FormLabel>
                                        <Input
                                            size="sm"
                                            ref={initialRef}
                                            placeholder="Enter User Name"
                                            color={"gray.700"}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel color={"gray.500"} fontSize={'sm'}>Email</FormLabel>
                                        <Input size="sm" placeholder="Enter User Email" color={"gray.700"}/>
                                    </FormControl>
                                </HStack>
                            </Box>
                            <Divider pt={3}/>
                            <Box Box w={"100%"}>
                                <HStack spacing={6}>
                                    <Box>
                                        <FormControl>
                                            <HStack spacing={0}>
                                                <FormLabel htmlFor="Entry Point" mb="0">
                                                    Entry Point
                                                </FormLabel>
                                                <Switch size="sm" id="entry-point"/>
                                            </HStack>
                                        </FormControl>
                                    </Box>

                                    <Box>
                                        <FormControl>
                                            <HStack spacing={0}>
                                                <FormLabel FormLabel htmlFor="Entry Point" mb="0">
                                                    HOD
                                                </FormLabel>
                                                <Switch size="sm" id="entry-point"/>
                                            </HStack>
                                        </FormControl>
                                    </Box>
                                    <Box>
                                        <FormControl>
                                            <HStack spacing={0}>
                                                <FormLabel FormLabel htmlFor="Entry Point" mb="0">
                                                    User
                                                </FormLabel>
                                                <Switch size="sm" id="entry-point"/>
                                            </HStack>
                                        </FormControl>
                                    </Box>
                                </HStack>
                            </Box>
                        </VStack>
                        <Box mt={5}>
                            <Wrap
                                spacing={2}
                                alignItems={"center"}
                                justifyContent={"center"}
                            >
                                <WrapItem pr={4}>
                                    <Checkbox borderColor={'dodgerblue'}>Checkbox</Checkbox>
                                </WrapItem>
                                <WrapItem pr={4}>
                                    <Checkbox borderColor={'dodgerblue'}>Checkbox</Checkbox>
                                </WrapItem>
                                <WrapItem pr={4}>
                                    <Checkbox borderColor={'dodgerblue'}>Checkbox</Checkbox>
                                </WrapItem>
                                <WrapItem pr={4}>
                                    <Checkbox borderColor={'dodgerblue'}>Checkbox</Checkbox>
                                </WrapItem>
                                <WrapItem pr={4}>
                                    <Checkbox borderColor={'dodgerblue'}>Checkbox</Checkbox>
                                </WrapItem>
                                <WrapItem pr={4}>
                                    <Checkbox borderColor={'dodgerblue'}>Checkbox</Checkbox>
                                </WrapItem>
                                <WrapItem pr={4}>
                                    <Checkbox borderColor={'dodgerblue'}>Checkbox</Checkbox>
                                </WrapItem>
                                <WrapItem pr={4}>
                                    <Checkbox borderColor={'dodgerblue'}>Checkbox</Checkbox>
                                </WrapItem>
                                <WrapItem pr={4}>
                                    <Checkbox borderColor={'dodgerblue'}>Checkbox</Checkbox>
                                </WrapItem>
                                <WrapItem pr={4}>
                                    <Checkbox borderColor={'dodgerblue'}>Checkbox</Checkbox>
                                </WrapItem>
                                <WrapItem pr={4}>
                                    <Checkbox borderColor={'dodgerblue'}>Checkbox</Checkbox>
                                </WrapItem>
                                <WrapItem pr={4}>
                                    <Checkbox borderColor={'dodgerblue'}>Checkbox</Checkbox>
                                </WrapItem>
                            </Wrap>
                        </Box>
                    </ModalBody>
                    <ModalFooter>
                        <Button size="sm" variant="solid" mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button size="sm" colorScheme="blue">
                            Confirm
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

const AssingJobModel = (props: any) => {

    const initialRef = React.useRef<any>(null);
    const {isOpen, onOpen, onClose} = useDisclosure();
    const finalRef = React.useRef<any>(null);

    const ModelHeader = () => {
        return (<VStack>
                <Box mt={3} width={"100%"}>
                    <HStack display={"flex"} justifyContent={"space-between"}>
                        <Box>
                            <Wrap spacing={2}>
                                <WrapItem>
                                    <Image w={4} pt={0.5} mt={0.2} src={priorityIcon}/>
                                    {/*<BsFillBrightnessHighFill size={40}/>*/}
                                </WrapItem>
                                <WrapItem fontWeight={700} alignItems={"center"}><Text color={"#6B778C"}
                                                                                       fontSize={"12px"}> ATL-001</Text></WrapItem>
                                <WrapItem><Text fontSize={"14px"}>Issue summary</Text></WrapItem>
                            </Wrap>
                        </Box>
                        <Box>
                            <Wrap spacing={6}>
                                <WrapItem>
                                    <Link><BiLockOpen size={20} color={"#4b5669"}/></Link>
                                </WrapItem>
                                <WrapItem>
                                    <Link><BsEyeFill size={20} color={"#4b5669"}/></Link>
                                </WrapItem>
                                <WrapItem>
                                    <Link><BiLike size={20} color={"#4b5669"}/></Link>
                                </WrapItem>
                                <WrapItem>
                                    <Link><BiShareAlt size={20} color={"#4b5669"}/></Link>
                                </WrapItem>
                                <WrapItem>
                                    <Link><BiDotsHorizontalRounded size={20} color={"#4b5669"}/></Link>
                                </WrapItem>
                                <WrapItem>
                                    <Link> <CloseIcon mt={"-10px"} onClick={onClose} color={"#4b5669"}/></Link>
                                </WrapItem>

                            </Wrap>
                        </Box>
                    </HStack>
                </Box>
                <Box alignSelf={"flex-start"}>
                    <Text fontWeight={500} fontSize={"24px"}> DOC2021-12-01DEC</Text>
                </Box>
            </VStack>
        )
    }

    const comments = (
        <>
            <WorkFlowItem title={"Send to ABC department"}/>
            <WorkFlowItem
                title={
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus"
                }/>
        </>
    );

    return (
        <>
            <Button size="sm" onClick={onOpen}>
                Assing Job Model
            </Button>

            <Modal size="5xl" isOpen={isOpen} onClose={onClose}>
                <ModalOverlay/>
                <ModalContent>

                    <ModalBody>
                        <ModelHeader/>
                        <Box>
                            <SimpleGrid columns={2} spacing={12}>
                                <VStack>
                                    <Box alignSelf={"flex-start"}>
                                        <Button fontWeight={500} color={"#42526E"} mt={"5px"} variant="solid" size="sm">
                                            Attach
                                        </Button>
                                    </Box>

                                    <Box fontWeight={500} w={"100%"} alignSelf={"flex-start"}>
                                        <Text fontSize="xs"> Description</Text>
                                        <Textarea/>
                                    </Box>

                                    <Box alignSelf={"flex-start"}>
                                        <Button size="sm" colorScheme="blue" mr={2}>
                                            Save
                                        </Button>
                                        <Button color={"#42526E"} size="sm" variant="solid" onClick={onClose}>
                                            Cancel
                                        </Button>

                                    </Box>

                                    <Tabs>
                                        <TabList>
                                            <Tab
                                                _focus={{
                                                    outline: "none !important",
                                                    boxShadow: "none !important",
                                                }}
                                            >
                                                <Text color={"#42526E"} fontWeight={"semibold"} fontSize="md">
                                                    Comments
                                                </Text>
                                            </Tab>
                                            <Tab
                                                _focus={{
                                                    outline: "none !important",
                                                    boxShadow: "none !important",
                                                }}
                                            >
                                                <Text color={"#42526E"} fontWeight={"semibold"} fontSize="md">
                                                    History
                                                </Text>
                                            </Tab>
                                        </TabList>

                                        <TabPanels>
                                            <TabPanel>
                                                <Flex>
                                                    <Text ps={1} fontSize={"11px"} fontWeight={"700"}
                                                          color={"#A6A6A6"}>TODAY</Text>
                                                </Flex>
                                                {comments}
                                            </TabPanel>
                                            <TabPanel>
                                                <Flex>
                                                    <Text ps={1} fontSize={"11px"} fontWeight={"700"}
                                                          color={"#A6A6A6"}>TODAY</Text>
                                                </Flex>
                                                {comments}
                                            </TabPanel>
                                        </TabPanels>
                                    </Tabs>
                                </VStack>
                                <Box>
                                    <Card border bottomSpace>
                                        <Text p={2}>Details</Text>
                                        <Divider/>
                                        <Flex marginTop={"5px"} alignSelf={"self-start"} pt={1}>
                                            <Checkbox ps={3} defaultIsChecked fontWeight={"400"}
                                                      color={"#091E42"}>Internal</Checkbox>
                                            <Checkbox ps={5} fontWeight={"400"} color={"#091E42"}>External</Checkbox>
                                        </Flex>
                                        <RichViewItem
                                            label={"Assignee"}
                                            avatar={"https://bit.ly/dan-abramov"}
                                            value={"Lorem ipsum"}
                                        />
                                        <RichViewItem label={"Due date"} value={"Dec 15 2021"}/>
                                        <RichViewItem label={"Note"} textarea/>
                                        <RichViewItem
                                            label={"Reporter"}
                                            avatar={"https://bit.ly/dan-abramov"}
                                            value={"Help desk - Dep of lorem"}
                                        />
                                        <RichViewItem
                                            label={"Reporter dep"}
                                            avatar={"https://bit.ly/dan-abramov"}
                                            value={"Department of lorem ipsum"}
                                        />
                                        <br/><br/><br/>
                                    </Card>
                                </Box>
                            </SimpleGrid>
                        </Box>
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

const MainPage = () => {
    return (
        <>
            <HStack spacing={2}>
                <AddDepartmentModel/>
                <NewUserModel/>
                <UserPrivilegesModel/>
                <AssingJobModel/>
                <ExternalAssigneesModel/>
            </HStack>
        </>
    );
};

export {AddDepartmentModel, NewUserModel, UserPrivilegesModel, MainPage};
