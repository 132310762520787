export const colourOptions = [
    {value: "blue", label: "Organization 1", color: "#0052CC"},
    {value: "purple", label: "Organization 2", color: "#5243AA"},
    {value: "red", label: "Organization 3", color: "#FF5630"},
    {value: "orange", label: "Organization 4", color: "#FF8B00"},
    {value: "yellow", label: "Organization 5", color: "#FFC400"},
    {value: "green", label: "Organization 6", color: "#36B37E"}
];

export const flavourOptions = [
    {value: "vanilla", label: "Organization 7", rating: "safe"},
    {value: "chocolate", label: "Organization 8", rating: "good"},
    {value: "strawberry", label: "Organization 9", rating: "wild"},
    {value: "salted-caramel", label: "Organization 10", rating: "crazy"}
];

export const DATA = [
    {
        label: "Internal",
        options: colourOptions
    },
    {
        label: "External",
        options: flavourOptions
    }
];

export default DATA;