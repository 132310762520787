import PageHeader from "../../components/page-header/page-header.component";
import {
    Box,
    FormControl,
    FormLabel,
    Input, Link, Spacer,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    Wrap,
    WrapItem
} from "@chakra-ui/react";
import SearchableSelect from "../../components/searchable-select/SearchableSelect";
import WorkFlowItem from "../../components/workflow-item/WorkFlowItem";

const header = (
    <>
        <PageHeader title={"Work flow: DOC2021-12-01DEC"}/>
        <Box py={2}>
            <Wrap spacing={"16px"}>
                <WrapItem>
                    <SearchableSelect label={"Organization"} placeholder={"Select organization"}/>
                </WrapItem>
                <WrapItem>
                    <SearchableSelect label={"Department"} placeholder={"Select Department"}/>
                </WrapItem>
                <WrapItem>
                    <FormControl w={254}>
                        <FormLabel htmlFor='fromDate'>From</FormLabel>
                        <Input placeholder={"From"} size={"sm"} id='fromDate' type='date'/>
                    </FormControl>
                </WrapItem>
                <WrapItem>
                    <FormControl w={254}>
                        <FormLabel htmlFor='toDate'>To</FormLabel>
                        <Input placeholder={"To"} size={"sm"} id='toDate' type='date'/>
                    </FormControl>
                </WrapItem>
            </Wrap>
        </Box>
    </>
)

const comments = (
    <>
        <WorkFlowItem title={"Send to ABC department"}/>
        <WorkFlowItem title={"Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus"}/>
        <WorkFlowItem title={"Lorem ipsum dolor sit amet, consectetur adipiscing elit"}/>
        <WorkFlowItem title={"Lorem ipsum dolor sit amet"}/>
    </>
)

const history = (
    <>
        <Text fontSize={'md'}>TODAY</Text>
        <WorkFlowItem title={"Send to ABC department"}/>
        <WorkFlowItem title={"Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus"}/>
        <WorkFlowItem title={"Lorem ipsum dolor sit amet, consectetur adipiscing elit"}/>
        <WorkFlowItem title={"Lorem ipsum dolor sit amet"}/>
        <Text fontSize={'md'}>YESTERDAY</Text>
        <WorkFlowItem title={"Lorem ipsum dolor sit amet, consectetur adipiscing elit"}/>
        <WorkFlowItem title={"Lorem ipsum dolor sit amet"}/>
    </>
)

const DocumentWorkflowPage = () => {
    return (
        <>
            {header}

            <Tabs>
                <TabList>
                    <Tab _focus={{
                        outline: "none !important",
                        boxShadow: "none !important",
                    }}>
                        <Text fontWeight={"semibold"} fontSize='md'>Comments</Text>
                    </Tab>
                    <Tab _focus={{
                        outline: "none !important",
                        boxShadow: "none !important",
                    }}>
                        <Text fontWeight={"semibold"} fontSize='md'>History</Text>
                    </Tab>
                    <Spacer/>
                    <Link mt={2}>+ Assign</Link>
                </TabList>

                <TabPanels>
                    <TabPanel>
                        {comments}
                    </TabPanel>
                    <TabPanel>
                        <p>{history}</p>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    );
}

export default DocumentWorkflowPage;