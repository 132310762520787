import PageHeader from "../../components/page-header/page-header.component";
import {
    Box,
    Stack,
    Grid,
    GridItem,
    Avatar,
    Text,
    Badge,
    HStack,
    TabList,
    Tab,
    TabPanels,
    TabPanel, Tabs, Textarea, Link, Flex, Spacer
} from "@chakra-ui/react";
import WorkFlowItem from "../../components/workflow-item/WorkFlowItem";
import ChatItemComponent from "../../components/chat-item/chat-item.component";
import {BiDotsHorizontalRounded} from "react-icons/bi";
import React from "react";

const comments = (
    <>
        <WorkFlowItem title={"Send to ABC department"}/>
        <WorkFlowItem title={"Lorem ipsum dolor sit amet, consectetur adipiscing elit ut.adadadasd adadad adadasdasdasd "}/>
        <WorkFlowItem title={"Lorem ipsum dolor sit amet, consectetur adipiscing elit"}/>
        <WorkFlowItem title={"Lorem ipsum dolor sit amet"}/>
    </>
)

const history = (
    <>
        <Text fontSize={'md'}>TODAY</Text>
        <WorkFlowItem title={"Lorem ipsum dolor sit amet"}/>
        <WorkFlowItem title={"Send to ABC department"}/>
        <WorkFlowItem title={"Lorem ipsum dolor sit amet, consectetur adipiscing elit"}/>
        <WorkFlowItem title={"Lorem ipsum dolor sit amet"}/>
    </>
)

const ApplicationViewPage = () => {
    return (
        <>
            <PageHeader title={'DOC2021-12-01DEC'}/>
            <Stack mt={5}>
                <Grid templateColumns='repeat(2, 1fr)' gap={2}>
                    <GridItem w='100%'>
                        <Grid templateColumns='repeat(6, 1fr)' gap={2} bg={'#F8F8F8'}>
                            <GridItem w='100%' colSpan={1} pl={3} py={3}>
                                <Avatar size='xl' name='Christian Nwamba' src='https://bit.ly/code-beast'/>
                            </GridItem>
                            <GridItem w='100%' colSpan={5} p={2}>
                                <Flex>
                                    <Text fontSize={'2xl'}>Dasun Edirisinghe</Text>
                                    <Spacer />
                                    <Link pr={2} pt={1}><BiDotsHorizontalRounded size={20} color={"#4b5669"}/></Link>
                                </Flex>


                                <Badge colorScheme='blue' variant='solid' fontSize={'xs'}>Default</Badge>

                                <HStack spacing={5} pt={2} pb={1}>
                                    <Box w='20%' color={'gray'} fontSize={'sm'}>Project :</Box>
                                    <Box w='80%' fontSize={'sm'}>Lorem Ipsum</Box>
                                </HStack>
                                <HStack spacing={5} pb={1}>
                                    <Box w='20%' color={'gray'} fontSize={'sm'}>Category :</Box>
                                    <Box w='80%' fontSize={'sm'}>Lorem Ipsum</Box>
                                </HStack>
                                <HStack pb={1}>
                                    <Box w='20%' color={'gray'} fontSize={'sm'}>Description :</Box>
                                </HStack>
                                <HStack>
                                    <Box w='100%' fontSize={'sm'}>It is a long established fact that a reader will be distracted by the
                                        readable content of a page when looking at its layout. The point of using Lorem
                                        Ipsum is that it has a more-or-less normal distribution of letters, as opposed
                                        to using 'Content here, content here', making it look like readable
                                        English.</Box>
                                </HStack>
                            </GridItem>
                        </Grid>

                        <Tabs>
                            <TabList>
                                <Tab _focus={{
                                    outline: "none !important",
                                    boxShadow: "none !important",
                                }}>
                                    <Text fontWeight={"semibold"} fontSize='md'>Comments</Text>
                                </Tab>
                                <Tab _focus={{
                                    outline: "none !important",
                                    boxShadow: "none !important",
                                }}>
                                    <Text fontWeight={"semibold"} fontSize='md'>History</Text>
                                </Tab>
                                <Spacer/>
                                <Link mt={2}>+ Assign</Link>
                            </TabList>

                            <TabPanels>
                                <TabPanel>
                                    {comments}
                                </TabPanel>
                                <TabPanel>
                                    {history}
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </GridItem>

                    <GridItem w='100%'>
                        <Grid templateColumns='repeat(12, 1fr)'>
                            <GridItem colSpan={1} ms={1}>
                                <Avatar size='sm' name='Kent Dodds' src='https://bit.ly/kent-c-dodds'/>
                            </GridItem>
                            <GridItem colSpan={11}>
                                <Textarea placeholder='' h={9}/>
                            </GridItem>
                        </Grid>

                        <ChatItemComponent backgroundColor={'#F9F9F9'} />
                        <Grid templateColumns='repeat(12, 1fr)'>
                            <GridItem colSpan={1}>

                            </GridItem>
                            <GridItem colSpan={11}>
                                <ChatItemComponent/>
                            </GridItem>
                        </Grid>
                        <ChatItemComponent />
                    </GridItem>

                </Grid>
            </Stack>
        </>
    )
}

export default ApplicationViewPage;